var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { staticClass: "app-main" },
      [
        _c(
          "transition",
          { attrs: { name: "fade-transform", mode: "out-in" } },
          [
            _c(
              "keep-alive",
              { attrs: { include: _vm.cachedViews } },
              [_c("router-view", { key: _vm.key })],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    this.$store.getters.showCopyright == 1
      ? _c("footer", { staticClass: "footer" }, [
          _c("p", [_vm._v("Copyright © 2021 傲万 All Rights Reserved")]),
          _vm._v(" "),
          _c("div", [_vm._v("上海傲万软件技术有限公司 版权所有")]),
          _vm._v(" "),
          _vm._m(0),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "align-items": "center",
          "text-align": "center",
        },
      },
      [
        _c(
          "a",
          {
            staticStyle: {
              display: "inline-block",
              "text-decoration": "none",
              height: "20px",
              "line-height": "20px",
            },
            attrs: {
              target: "_blank",
              href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502016567",
            },
          },
          [
            _c("img", {
              staticStyle: { float: "left", width: "20px", height: "20px" },
              attrs: {
                src: "https://img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png",
              },
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticStyle: {
                  float: "left",
                  height: "20px",
                  "line-height": "20px",
                  margin: "0px 0px 0px 5px",
                  color: "#939393",
                },
              },
              [_vm._v("\n          沪公网安备31011502016567号\n        ")]
            ),
          ]
        ),
        _vm._v("  |   \n        "),
        _c(
          "p",
          {
            staticStyle: {
              float: "left",
              height: "20px",
              "line-height": "20px",
              margin: "10px 0px 10px 5px",
              color: "#939393",
            },
          },
          [
            _c(
              "a",
              {
                staticStyle: { color: "#939393" },
                attrs: { target: "_blank", href: "http://beian.miit.gov.cn" },
              },
              [_vm._v("沪ICP备20020664号-1")]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }