import Mock from 'mockjs';
var data = Mock.mock({
  'codes': [{
    name: 'option.common.status',
    data: [{
      code: 'b_organization_status',
      value: '1',
      label: 'Active'
    }, {
      code: 'b_organization_status',
      value: '0',
      label: 'Inactive'
    }]
  }, {
    name: 't_shop.b_shop_level',
    data: [{
      code: 'b_shop_level',
      value: '1',
      label: 'Level 1'
    }, {
      code: 'b_shop_level',
      value: '2',
      label: 'Level 2'
    }, {
      code: 'b_shop_level',
      value: '3',
      label: 'Level 3'
    }]
  }, {
    name: 'common.b_region_province_city',
    data: [{
      code: 'b_region',
      value: '01',
      label: 'North',
      children: [{
        code: 'b_provice',
        value: '0101',
        label: 'Beijing',
        children: [{
          code: 'b_city',
          value: '010101',
          label: 'Beijing'
        }]
      }, {
        code: 'b_provice',
        value: '0102',
        label: 'Liaoning',
        children: [{
          code: 'b_city',
          value: '010201',
          label: 'Shenyang'
        }, {
          code: 'b_city',
          value: '010202',
          label: 'Dalian'
        }, {
          code: 'b_city',
          value: '010203',
          label: 'Panjing'
        }]
      }]
    }, {
      code: 'b_region',
      value: '02',
      label: 'East',
      children: [{
        code: 'b_provice',
        value: '0201',
        label: 'Shanghai',
        children: [{
          code: 'b_city',
          value: '020101',
          label: 'Shanghai'
        }]
      }, {
        code: 'b_provice',
        value: '0202',
        label: 'Jiangsu',
        children: [{
          code: 'b_city',
          value: '020201',
          label: 'Nanjing'
        }, {
          code: 'b_city',
          value: '020202',
          label: 'Suzhou'
        }, {
          code: 'b_city',
          value: '020203',
          label: 'Wuxi'
        }]
      }, {
        code: 'b_provice',
        value: '0203',
        label: 'Zhejiang',
        children: [{
          code: 'b_city',
          value: '020301',
          label: 'Hangzhou'
        }, {
          code: 'b_city',
          value: '020302',
          label: 'Wenzhou'
        }, {
          code: 'b_city',
          value: '020303',
          label: 'Ningbo'
        }]
      }]
    }, {
      code: 'b_region',
      value: '03',
      label: 'Central',
      children: [{
        code: 'b_provice',
        value: '0301',
        label: 'Henan',
        children: [{
          code: 'b_city',
          value: '030101',
          label: 'Zhengzhou'
        }]
      }]
    }]
  }]
});
export default [{
  url: '/sys/code-data-dictionary',
  type: 'get',
  response: function response(config) {
    return data;
  }
}];