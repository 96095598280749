var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      staticClass: "international",
      attrs: { trigger: "click" },
      on: { command: _vm.handleShift },
    },
    [
      _c(
        "div",
        {
          staticStyle: { "font-size": "14px" },
          on: { click: _vm.getListData },
        },
        [
          _vm._v("\n    Current Client: " + _vm._s(this.clientName) + " "),
          _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.listData, function (item) {
          return _c(
            "el-dropdown-item",
            {
              key: item.cid,
              attrs: { disabled: item.cid === _vm.clientId, command: item.cid },
            },
            [_vm._v("\n      " + _vm._s(item.cclientName) + "\n    ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }