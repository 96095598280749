// translate router.meta.title, be used in breadcrumb sidebar tagsview
export function generateTitle(title) {
  var hasKey = this.$te('route.' + title);
  if (hasKey) {
    // $t :this method from vue-i18n, inject in @/lang/index.js
    var translatedTitle = this.$t('route.' + title);
    return translatedTitle;
  }
  return title;
}
export function generateMetaTitle(item) {
  try {
    var title = item.meta.title;
    if (typeof title == 'function') {
      if (item.path.indexOf(':') > -1 && this.$route.meta.title === title) {
        title = title(this.$route);
      } else {
        title = title(item);
      }
    }
    return generateTitle.call(this, title);
  } catch (e) {
    console.log(e);
    return generateTitle.call(this, item.meta.title);
  }
}