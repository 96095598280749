import Mock from 'mockjs';
var data = Mock.mock({
  'items|10': [{
    id: '@id',
    name: '@title(2,4)',
    'brand|1': ['Nestle', 'P&G', 'Unilever'],
    category: '@string("lower", 5, 10)',
    sub_category: '@title(1,1)',
    price: '@float(0,100,2,2)',
    create_time: '@datetime',
    stock: '@integer(100, 5000)'
  }]
});
export default [{
  url: '/product/list',
  type: 'get',
  response: function response(config) {
    var items = data.items;
    return {
      total: items.length,
      data: items
    };
  }
}];