//
//
//
//
//
//
//
//

import { getToken } from '@/utils/auth'; // get token from cookie

export default {
  name: 'Index',
  data: function data() {
    return {
      path: null,
      //process.env.VUE_APP_WEBSOCKET_URL,
      socket: null,
      heartCheck: null
    };
  },
  created: function created() {
    // let _this = this
    // this.heartCheck = {
    //   timeout: 10000,
    //   timeoutObj: null, 
    //   reset: function(){ 
    //     clearInterval(this.timeoutObj); 
    //     return this; 
    //   }, 
    //   start: function(){ 
    //     this.timeoutObj = setInterval(function(){ 
    //       //console.log(_this.socket)
    //       _this.send("HeartBeat"); 
    //       //console.log("HeartBeat"); 
    //     }, this.timeout) 
    //   }
    // }
  },
  mounted: function mounted() {
    // 初始化
    //console.log("mounted")
    //this.init()
  },
  destroyed: function destroyed() {
    // 销毁监听
    //this.socket.onclose = this.close
  },
  methods: {
    init: function init() {
      // if(this.socket!=null){
      //   console.log("init not null!")
      //   return
      // }
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        var token = getToken();
        this.socket = new WebSocket(this.path + '/' + token);
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket关闭
        this.socket.onclose = this.close;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
      }
    },
    open: function open() {
      // console.log("open")
      // console.log(this.socket)
      if (this.socket.readyState == 1) {
        console.log("socket连接成功");
        this.heartCheck.reset().start();
      }
    },
    error: function error() {
      console.log("连接错误");
    },
    getMessage: function getMessage(msg) {
      //console.log(msg.data)
      if (msg.data == 'REFRESH_TABLE_DATA_DICTIONARY') {
        this.loadTableDD(this.$store.getters.language);
      }
      if (msg.data == 'REFRESH_CODE_DATA_DICTIONARY') {
        this.loadCodeDD(this.$store.getters.language);
      }
    },
    send: function send(params) {
      //console.log(this.socket)
      if (this.socket.readyState == 3) {
        // this.socket.close()
        // this.socket=null
        this.init();
      } else {
        this.socket.send(params);
      }
    },
    close: function close() {
      console.log("socket已经关闭");
    },
    // async loadDataDictionary(lang) {
    //   await Promise.all([this.loadTableDD(lang),this.loadCodeDD(lang)])   
    // },
    loadTableDD: function loadTableDD(lang) {
      this.$store.dispatch('tableDataDictionary/getTableDataDictionary', lang).then(function () {
        console.log('Load tableDataDictionary success');
      }).catch(function () {
        console.log('Load tableDataDictionary failed');
      });
    },
    loadCodeDD: function loadCodeDD(lang) {
      this.$store.dispatch('codeDataDictionary/getCodeDataDictionary', lang).then(function () {
        console.log('Load codeDataDictionary success');
      }).catch(function () {
        console.log('Load codeDataDictionary failed');
      });
    }
  }
};