import request from '@/utils/request';
export function current() {
  return request({
    url: '/client/current',
    method: 'post',
    params: {}
  });
}
export function all() {
  return request({
    url: '/client/all',
    method: 'post',
    params: {}
  });
}
export function shift(id) {
  return request({
    url: '/client/shift',
    method: 'post',
    params: {
      id: id
    }
  });
}
export function refreshTableDataDictionary(language) {
  return request({
    url: '/sys/refresh-table-data-dictionary',
    method: 'get',
    params: {
      language: language
    }
  });
}
export function refreshCodeDataDictionary(language) {
  return request({
    url: '/sys/refresh-code-data-dictionary',
    method: 'get',
    params: {
      language: language
    }
  });
}