export default {
  index: {
    system_name: '',
    system_name1: 'Master Data System',
    system_name2: 'Distributor Info System',
    hello: 'Hello',
    your_role: 'Your role is',
    switch_language: 'Switch Language Success',
    element_ui_size_large: 'Large',
    element_ui_size_small: 'Small',
    switch_size: 'Switch Size Success',
    shop_audit: {
      view_type1: 'Pending',
      view_type2: 'Dealed',
      view_type3: 'My applications',
      more: 'VIEW MORE',
      add_shop: 'NEW STORE',
      update_shop: 'UPDATE STORE'
    }
  },
  route: {
    dashboard: 'Dashboard',
    dashboard2: 'Dashboard',
    uploadmonitor: 'Upload Monitor',
    master_data: 'Master Data',
    system_setting: 'System Setting',
    distributordata: 'Distributor Data',
    organization: 'Organization',
    organization_list: 'List Mode',
    organization_tree: 'Tree Mode',
    organization_add: 'New Organization',
    organization_edit: 'Edit Organization',
    organization_delete: 'Delete Organization',
    city: '2.GEO',
    city_list: 'List Mode',
    city_tree: 'Mode',
    city_add: 'New GEO',
    city_edit: 'Edit GEO',
    city_delete: 'Delete GEO',
    user: 'User',
    user_list: 'User',
    user_add: 'New User',
    user_edit: 'Edit User',
    user_delete: 'Delete User',
    position: 'Position',
    position_list: 'Position',
    position_add: 'New Position',
    position_edit: 'Edit Position',
    position_delete: 'Delete Position',
    role: 'Privilege Role',
    role_list: 'Privilege Role',
    role_add: 'New Privilege Role',
    role_edit: 'Edit Privilege Role',
    role_delete: 'Delete Privilege Role',
    roleworkflow: 'Workflow Role',
    roleworkflow_list: 'Workflow Role',
    roleworkflow_add: 'New Workflow Role',
    roleworkflow_edit: 'Edit Workflow Role',
    roleworkflow_delete: 'Delete Workflow Role',
    resource: 'Resource',
    resource_list: 'List Mode',
    resource_tree: 'Tree Mode',
    resource_add: 'New Resource',
    resource_edit: 'Edit Resource',
    resource_delete: 'Delete Resource',
    distributor: 'Distributor',
    distributor_list: 'Distributor',
    distributor_add: 'New Distributor',
    distributor_edit: 'Edit Distributor',
    distributor_delete: 'Delete Distributor',
    distributorchange: 'Distributor Change',
    distributorchange_list: 'Distributor Change',
    distributorchange_add: 'New Distributor Change',
    distributorchange_edit: 'Edit Distributor Change',
    distributorchange_delete: 'Delete Distributor Change',
    product: 'Product',
    product_list: 'Product',
    product_add: 'New Product',
    product_edit: 'Edit Product',
    product_delete: 'Delete Product',
    productprice: 'Product Price',
    productprice_list: 'Product Price',
    productprice_add: 'New Product Price',
    productprice_edit: 'Edit Product Price',
    productprice_delete: 'Delete Product Price',
    productunitexchange: 'Product Unit Exchange',
    productunitexchange_list: 'Product Unit Exchange',
    productunitexchange_add: 'New Product Unit Exchange',
    productunitexchange_edit: 'Edit Product Unit Exchange',
    productunitexchange_delete: 'Delete Product Unit Exchange',
    productunitexchange_units: 'Edit Product Units',
    productunitcheck: 'Check Product Unit Exchange',
    shop: 'Outlet',
    shop_list: 'Outlet',
    shop_add: 'New Outlet',
    shop_edit: 'Edit Outlet',
    shop_delete: 'Delete Outlet',
    shop_audit: 'Outlet Audit',
    client: 'Client',
    client_list: 'Client',
    client_add: 'New Client',
    client_edit: 'Edit Client',
    client_delete: 'Delete Client',
    client_app_config_data_center: 'App Data Center',
    t_client_project_config_code: 'Client Project Config',
    t_client_project_config_code_list: 'Client Project Config',
    t_client_project_config_code_add: 'New Client Project Config',
    t_client_project_config_code_edit: 'Edit Client Project Config',
    t_client_project_config_code_delete: 'Delete Client Project Config',
    distributor_app_code: 'Distributor App Management',
    distributor_app_code_list: 'Distributor App Code',
    distributor_app_code_add: 'New Distributor App Code',
    distributor_app_code_edit: 'Edit Distributor App Code',
    distributor_app_code_delete: 'Delete Distributor App Code',
    app_config_datacenter_code: 'Server Config',
    app_config_datacenter_code_list: 'Server Config',
    app_config_datacenter_code_add: 'Add Server Config',
    app_config_datacenter_code_edit: 'Edit Server Config',
    app_config_datacenter_code_delete: 'Delete Server Config',
    t_app_conf_server_datacenter_domain_code: 'Domain Config',
    t_app_conf_server_datacenter_domain_code_list: 'Domain Config',
    t_app_conf_server_datacenter_domain_code_add: 'Add Domain Config',
    t_app_conf_server_datacenter_domain_code_edit: 'Edit Domain Config',
    t_app_conf_server_datacenter_domain_code_delete: 'Delete Domain Config',
    t_app_conf_server_datacenter_ip_code: 'IP Config',
    t_app_conf_server_datacenter_ip_code_list: 'IP Config',
    t_app_conf_server_datacenter_ip_code_add: 'Add IP Config',
    t_app_conf_server_datacenter_ip_code_edit: 'Edit IP Config',
    t_app_conf_server_datacenter_ip_code_delete: 'Delete IP Config',
    t_app_conf_server_url_code: 'Server Url Config',
    t_app_conf_server_url_code_list: 'Server Url Config',
    t_app_conf_server_url_code_add: 'Add Server Url Config',
    t_app_conf_server_url_code_edit: 'Edit Server Url Config',
    t_app_conf_server_url_code_delete: 'Delete Server Url Config',
    t_app_conf_application_property_code: 'Application Property Config',
    t_app_conf_application_property_code_list: 'Application Property Config',
    t_app_conf_application_property_code_add: 'Add Application Property Config',
    t_app_conf_application_property_code_edit: 'Edit Application Property Config',
    t_app_conf_application_property_code_delete: 'Delete Application Property Config',
    t_app_conf_application_code: 'Application Xml Config',
    t_app_conf_application_code_list: 'Application Xml Config',
    t_app_conf_application_code_add: 'Add Application Xml Config',
    t_app_conf_application_code_edit: 'Edit Application Xml Config',
    t_app_conf_application_code_delete: 'Delete Application Xml Config',
    t_app_conf_db_extract_conn_parameter_code: 'DB Conn Parameter Config',
    t_app_conf_db_extract_conn_parameter_code_list: 'DB Conn Parameter Config',
    t_app_conf_db_extract_conn_parameter_code_add: 'Add DB Conn Parameter Config',
    t_app_conf_db_extract_conn_parameter_code_edit: 'Edit DB Conn Parameter Config',
    t_app_conf_db_extract_conn_parameter_code_delete: 'Delete DB Conn Parameter Config',
    t_app_conf_db_extract_file_code: 'DB Extract File Config',
    t_app_conf_db_extract_file_code_list: 'DB Extract File Config',
    t_app_conf_db_extract_file_code_add: 'Add DB Extract File Config',
    t_app_conf_db_extract_file_code_edit: 'Edit DB Extract File Config',
    t_app_conf_db_extract_file_code_delete: 'Delete DB Extract File Config',
    t_app_conf_db_extract_file_parameter_value_code: 'DB Extract Parameter Config',
    t_app_conf_db_extract_file_parameter_value_code_list: 'DB Extract Parameter Config',
    t_app_conf_db_extract_file_parameter_value_code_add: 'Add DB Extract Parameter Config',
    t_app_conf_db_extract_file_parameter_value_code_edit: 'Edit DB Extract Parameter Config',
    t_app_conf_db_extract_file_parameter_value_code_delete: 'Delete DB Extract Parameter Config',
    t_export_file_code: 'Export Manage',
    t_export_file_code_list: 'Export File List',
    t_report_subscribe_code: 'Report Subscribe',
    t_report_subscribe_code_list: 'Report Subscribe List',
    t_report_subscribe_code_add: 'Add Report Subscribe',
    t_report_subscribe_code_edit: 'Edit Report Subscribe',
    t_report_subscribe_code_delete: 'Delete Report Subscribe',
    t_report_subscribe_run_code: 'Report Subscribe Run Log',
    t_prop_option_code: 'Dictionary Management',
    t_prop_option_code_list: 'Dictionary Management',
    t_export_file_code_add: 'Add',
    t_export_file_code_edit: 'Edit',
    t_export_file_code_delete: 'Delete',
    t_app_conf_job_code: 'Job Config',
    t_app_conf_job_code_list: 'Job Config',
    t_app_conf_job_code_add: 'Add Job Config',
    t_app_conf_job_code_edit: 'Edit Job Config',
    t_app_conf_job_code_delete: 'Delete Job Config',
    install_check_compare: 'ERP Data Comparison',
    install_check_compare_purchase: 'Purchase Data',
    install_check_compare_sales: 'Sales Data',
    install_check_compare_stock: 'Stock Data',
    distributorshop: 'Distributor Outlet',
    distributorshop_list: 'Distributor Outlet',
    shopmatch: 'Outlet Mapping',
    shopmatch_overview: 'Outlet Mapping Overview',
    shopmatch_shoplist: 'Outlet Mapping Confirm',
    shopmatch_client: 'Outlet Mapping',
    shopmatch_client_overview: 'Outlet Mapping Confirm Overview',
    shopmatch_client_shoplist: 'Outlet Mapping Confirm',
    distributorproduct: 'Distributor Product',
    distributorproduct_list: 'Distributor Product',
    productmatch: 'Product Mapping',
    productmatch_overview: 'Product Mapping Overview',
    productmatch_productlist: 'Product Mapping Confirm',
    productmatch_client: 'Product Mapping',
    productmatch_client_overview: 'Product Mapping Confirm Overview',
    productmatch_client_productlist: 'Product Mapping Confirm',
    distributorclientbilltype: 'Bill Type & Status',
    distributorclientbilltype_list: 'Bill Type & Status',
    distributorclientbilltype_add: 'New Mapping',
    distributorclientbilltype_edit: 'Edit Mapping',
    distributorclientbilltype_delete: 'Delete Mapping',
    data_template: 'Data template',
    data_template_list: 'Download File Template',
    data_template_detail: 'Template detail',
    import_data: 'Import Data',
    dms_rawdata: 'Raw Data',
    dms_rawdatasales: 'Sales Raw Data',
    dms_rawdatasales_list: 'Sales Raw Data',
    dms_rawdatasales_add: 'New Sales Raw Data',
    dms_rawdatasales_edit: 'Edit Sales Raw Data',
    dms_rawdatasales_delete: 'Delete Sales Raw Data',
    dms_rawdatatransfer: 'Transfer Raw Data',
    dms_rawdatatransfer_list: 'Transfer Raw Data',
    dms_rawdatatransfer_add: 'New Transfer Raw Data',
    dms_rawdatatransfer_edit: 'Edit Transfer Raw Data',
    dms_rawdatatransfer_delete: 'Delete Transfer Raw Data',
    dms_rawdatapurchase: 'Purchase Raw Data',
    dms_rawdatapurchase_list: 'Purchase Raw Data',
    dms_rawdatapurchase_add: 'New Purchase Raw Data',
    dms_rawdatapurchase_edit: 'Edit Purchase Raw Data',
    dms_rawdatapurchase_delete: 'Delete Purchase Raw Data',
    dms_rawdatastock: 'Stock Raw Data',
    dms_rawdatastock_list: 'Stock Raw Data',
    dms_rawdatastock_add: 'New Stock Raw Data',
    dms_rawdatastock_edit: 'Edit Stock Raw Data',
    dms_rawdatastock_delete: 'Delete Stock Raw Data',
    dms_rawdatachange: 'Stock Change Raw Data',
    dms_rawdatachange_list: 'Stock Change Raw Data',
    dms_rawdatachange_add: 'New Stock Change Raw Data',
    dms_rawdatachange_edit: 'Edit Stock Change Raw Data',
    dms_rawdatachange_delete: 'Delete Stock Change Raw Data',
    dms_matcheddata: 'Mapped Data',
    dms_matcheddatasales: 'Sales Mapped Data',
    dms_matcheddatasales_list: 'Sales Mapped Data',
    dms_matcheddatasales_add: 'New Sales Mapped Data',
    dms_matcheddatasales_edit: 'Edit Sales Mapped Data',
    dms_matcheddatasales_delete: 'Delete Sales Mapped Data',
    dms_matcheddatatransfer: 'Transfer Mapped Data',
    dms_matcheddatatransfer_list: 'Transfer Mapped Data',
    dms_matcheddatatransfer_add: 'New Transfer Mapped Data',
    dms_matcheddatatransfer_edit: 'Edit Transfer Mapped Data',
    dms_matcheddatatransfer_delete: 'Delete Transfer Mapped Data',
    dms_matcheddatapurchase: 'Purchase Mapped Data',
    dms_matcheddatapurchase_list: 'Purchase Mapped Data',
    dms_matcheddatapurchase_add: 'New Purchase Mapped Data',
    dms_matcheddatapurchase_edit: 'Edit Purchase Mapped Data',
    dms_matcheddatapurchase_delete: 'Delete Purchase Mapped Data',
    dms_matcheddatastock: 'Stock Mapped Data',
    dms_matcheddatastock_list: 'Stock Mapped Data',
    dms_matcheddatastock_add: 'New Stock Mapped Data',
    dms_matcheddatastock_edit: 'Edit Stock Mapped Data',
    dms_matcheddatastock_delete: 'Delete Stock Mapped Data',
    dms_matcheddatachange: 'Stock Change Mapped Data',
    dms_matcheddatachange_list: 'Stock Change Mapped Data',
    dms_matcheddatachange_add: 'New Stock Change Mapped Data',
    dms_matcheddatachange_edit: 'Edit Stock Change Mapped Data',
    dms_matcheddatachange_delete: 'Delete Stock Change Mapped Data',
    dms_converteddata: 'Converted Data',
    dms_converteddatasales: 'Sales Converted Data',
    dms_converteddatasales_list: 'Sales Converted Data',
    dms_converteddatasales_add: 'New Sales Converted Data',
    dms_converteddatasales_edit: 'Edit Sales Converted Data',
    dms_converteddatasales_delete: 'Delete Sales Converted Data',
    dms_converteddatatransfer: 'Transfer Converted Data',
    dms_converteddatatransfer_list: 'Transfer Converted Data',
    dms_converteddatatransfer_add: 'New Transfer Converted Data',
    dms_converteddatatransfer_edit: 'Edit Transfer Converted Data',
    dms_converteddatatransfer_delete: 'Delete Transfer Converted Data',
    dms_converteddatapurchase: 'Purchase Converted Data',
    dms_converteddatapurchase_list: 'Purchase Converted Data',
    dms_converteddatapurchase_add: 'New Purchase Converted Data',
    dms_converteddatapurchase_edit: 'Edit Purchase Converted Data',
    dms_converteddatapurchase_delete: 'Delete Purchase Converted Data',
    dms_converteddatastock: 'Stock Converted Data',
    dms_converteddatastock_list: 'Stock Converted Data',
    dms_converteddatastock_add: 'New Stock Converted Data',
    dms_converteddatastock_edit: 'Edit Stock Converted Data',
    dms_converteddatastock_delete: 'Delete Stock Converted Data',
    dms_converteddatachange: 'Stock Change Converted Data',
    dms_converteddatachange_list: 'Stock Change Converted Data',
    dms_converteddatachange_add: 'New Stock Change Converted Data',
    dms_converteddatachange_edit: 'Edit Stock Change Converted Data',
    dms_converteddatachange_delete: 'Delete Stock Change Converted Data',
    dms_settledata: 'Settled Data',
    dms_settledatasales: 'Sales Settled Data',
    dms_settledatasales_list: 'Sales Settled Data',
    dms_settledatasales_add: 'New Sales Settled Data',
    dms_settledatasales_edit: 'Edit Sales Settled Data',
    dms_settledatasales_delete: 'Delete Sales Settled Data',
    dms_settledatatransfer: 'Transfer Settled Data',
    dms_settledatatransfer_list: 'Transfer Settled Data',
    dms_settledatatransfer_add: 'New Transfer Settled Data',
    dms_settledatatransfer_edit: 'Edit Transfer Settled Data',
    dms_settledatatransfer_delete: 'Delete Transfer Settled Data',
    dms_settledatapurchase: 'Purchase Settled Data',
    dms_settledatapurchase_list: 'Purchase Settled Data',
    dms_settledatapurchase_add: 'New Purchase Settled Data',
    dms_settledatapurchase_edit: 'Edit Purchase Settled Data',
    dms_settledatapurchase_delete: 'Delete Purchase Settled Data',
    dms_settledatastock: 'Stock Settled Data',
    dms_settledatastock_list: 'Stock Settled Data',
    dms_settledatastock_add: 'New Stock Settled Data',
    dms_settledatastock_edit: 'Edit Stock Settled Data',
    dms_settledatastock_delete: 'Delete Stock Settled Data',
    dms_settledatachange: 'Stock Change Settled Data',
    dms_settledatachange_list: 'Stock Change Settled Data',
    dms_settledatachange_add: 'New Stock Change Settled Data',
    dms_settledatachange_edit: 'Edit Stock Change Settled Data',
    dms_settledatachange_delete: 'Delete Stock Change Settled Data',
    pos_rawdata: 'Raw Data',
    pos_rawdatasales: 'Sales Raw Data',
    pos_rawdatatransfer: 'Transfer Raw Data',
    pos_rawdatapurchase: 'Purchase Raw Data',
    pos_rawdatastock: 'Stock Raw Data',
    pos_rawdatachange: 'Stock Change Raw Data',
    pos_matcheddata: 'Mapped Data',
    pos_matcheddatasales: 'Sales Mapped Data',
    pos_matcheddatatransfer: 'Transfer Mapped Data',
    pos_matcheddatapurchase: 'Purchase Mapped Data',
    pos_matcheddatastock: 'Stock Mapped Data',
    pos_matcheddatachange: 'Stock Change Mapped Data',
    pos_converteddata: 'Converted Data',
    pos_converteddatasales: 'Sales Converted Data',
    pos_converteddatatransfer: 'Transfer Converted Data',
    pos_converteddatapurchase: 'Purchase Converted Data',
    pos_converteddatastock: 'Stock Converted Data',
    pos_converteddatachange: 'Stock Change Converted Data',
    pos_settledata: 'Settled Data',
    pos_settledatasales: 'Sales Settled Data',
    pos_settledatatransfer: 'Transfer Settled Data',
    pos_settledatapurchase: 'Purchase Settled Data',
    pos_settledatastock: 'Stock Settled Data',
    pos_settledatachange: 'Stock Change Settled Data',
    distributorshopmatch: 'Distributor Outlet Mapping',
    distributorshopmatch_list: 'Distributor Outlet Mapping',
    distributorshopmatch_edit: 'Edit Distributor Outlet Mapping',
    distributorproductmatch: 'Distributor Product Mapping',
    distributorproductmatch_list: 'Distributor Product Mapping',
    distributorproductmatch_edit: 'Edit Distributor Product Mapping',
    distributorbalance: 'Distributor Balance',
    distributorbalance_check: 'Balance Check',
    distributorbalance_detail: 'Balance Detail',
    duplicate_shop_check: 'Duplicated Outlet Check',
    productmatchcheck: 'Product Mapping Check',
    productmatchcheck1: 'Product Mapping Check 1',
    productmatchcheck2: 'Product Mapping Check 2',
    productmatchcheck3: 'Product Mapping Check 3',
    productmatchcheck4: 'Product Mapping Check 4',
    productmatchmarkuprate: 'Product Mapping Mark-up Rate Check',
    client_period: 'Settled Period',
    client_period_list: 'Settled Period',
    client_period_add: 'New Settled Period',
    client_period_edit: 'Edit Settled Period',
    client_portal_upload_schedule: 'Upload Schedule',
    client_portal_upload_schedule_list: 'Upload Schedule',
    client_portal_upload_schedule_add: 'New Upload Schedule',
    client_portal_upload_schedule_edit: 'Edit Upload Schedule',
    app_manager: 'App Manager',
    data_view_dms: 'DMS Data View',
    data_view_pos: 'POS Data View',
    data_match: 'Data Mapping',
    data_check: 'Data Check',
    data_dictionary_manage: 'Dictionary Manage',
    data_dictionary_option: 'Data Dictionary',
    data_dictionary_prop: 'Dictionary Table',
    api_log: 'Log Management',
    wholesaler: 'Wholesaler',
    wholesaler_add: 'New Wholesaler',
    wholesaler_edit: 'Edit Wholesaler',
    sapshop: 'SAP Outlet',
    sapshop_list: 'SAP Outlet',
    sapshop_add: 'New SAP Outlet',
    sapshop_edit: 'Edit SAP Outlet',
    sap_mdm_shop_match: 'SAP-MDM Code Match',
    gm_mercury_product: 'Mercury Product',
    gm_sap_file_load: 'SAP File Load',
    todo4: 'Announcement',
    todo5: 'Data Exchange',
    todo6: 'Collection Field Maintenance',
    todo14: 'Client Announcement',
    report: 'Report Management',
    report_distributor_sales_product_top20: 'Distributor Top 20 Product Report',
    report_distributor_sales_shop_top20: 'Distributor Top 20 Outlet Report',
    report_distributor_pss: 'Distributor Purchase, Sales And Stock Report',
    report_distributor_stock: 'Distributor Stock Report',
    report_distributor_sales: 'Distributor Sales Report',
    report_distributor_sales_rank: 'Distributor Sales Rank Report',
    report_product_distribution: 'Product Distribution Report',
    report_product_sales_increase_rate: 'Product Sales Increase Rate Top 10 Report',
    todo27: 'First Level Distributor Delivery Report',
    todo28: 'Secondary Distributor Delivery Report',
    report_shop_number: 'DMS Outlet Number Report',
    report_shop_new: 'DMS New Outlet Report',
    report_shop_lost: 'DMS Lost Outlet Report',
    report_mdm_shop_number: 'Master Data Outlet Number Report',
    demo: 'Demo',
    demo_tianyancha: 'Tianyancha',
    demo_map_gm: 'Outlets Map'
  },
  navbar: {
    dashboard: 'Dashboard',
    logOut: 'Log Out',
    modifyPass: 'Modify Password',
    oldPass: 'Old password',
    newPass: 'New password',
    newPass2: 'Enter again',
    diffPass: 'The two passwords are inconsistent',
    size: 'Change Size'
  },
  login: {
    title: '',
    resetPass: 'Reset Password',
    logIn: 'Login',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    send_reset_email_success: 'Submitted successfully, please check the email',
    reset_pass_success: 'Password reset successfully',
    token_timeout: 'Token is timeout, please login again'
  },
  label: {
    pleaseSelect: 'Please Select',
    inputKeywordToFilter: 'Input Keyword To Filter',
    title404: 'Sorry, the page does not exist.',
    message404: 'Please check the URL you entered, or click the button below to return to the homepage.',
    backToHome: 'BACK TO HOME',
    recordChangeLog: 'Record Change Log',
    similarShop: 'Similar Outlets',
    audit: 'Audit',
    auditRecordChange: 'Record Change',
    auditSteps: 'Audit Steps',
    auditComment: 'Audit Comment',
    auditCommentNotEmpty: 'Audit comment is required',
    auditNewShop: 'New Outlet Request',
    auditEditShop: 'Update Outlet Request',
    newShop: 'New Outlet',
    view: 'View',
    checkSimilarShop: 'Suspected Duplication'
  },
  button: {
    refresh: 'REFRESH',
    search: 'SEARCH',
    add: 'ADD',
    export: 'EXPORT',
    import: 'IMPORT',
    edit: 'EDIT',
    delete: 'DELETE',
    cancel: 'CANCEL',
    reset: 'RESET',
    confirm: 'CONFIRM',
    close: 'CLOSE',
    back: 'BACK',
    save: 'SAVE',
    clear: 'CLEAR',
    Mapping: 'Mapping',
    matchConfirm: 'CONFIRM',
    batchConfirm: 'BATCH CONFIRM',
    calculateData: 'CALCULATE DATA',
    operation: 'OPERATION',
    runSettle: 'RUN SETTLE',
    runMatch: 'RUN MAPPING',
    runDuplicateShopCheck: 'RUN DUPLICATED SHOP CHECK',
    expand: 'EXPAND',
    collapse: 'COLLAPSE',
    updateHistoricalData: 'UPDATE HISTORICAL DATA',
    reportEditor: 'REPORT EDITOR',
    generateChart: 'GENERATE CHART',
    exportImage: 'EXPORT IMAGE',
    pass: 'PASS',
    reject: 'REJECT',
    confirmAddShop: 'CONFIRM ADD NEW OUTLET',
    confirmDuplicated: 'PASS',
    confirmNotDuplicated: 'REJECT',
    reApply: 'APPLY AGAIN',
    uploadData: 'UPLOAD DATA',
    confirmUpload: 'CONFIRM UPLOAD',
    openUploadWindow: 'OPEN UPLOAD WINDOW',
    zeroDeclaration: 'ZERO DECLARATION',
    refreshDD: 'REFRESH SYSTEM CACHE',
    loadSapFile: 'Load SAP File From FTP'
  },
  loading: {
    default: 'Loading...',
    login: 'Login...'
  },
  messageBox: {
    alert: {
      confirmButton: 'CONFIRM'
    },
    confirm: {
      confirmButton: 'CONFIRM',
      cancelButton: 'CANCEL',
      title: 'Confirmation',
      body: 'Are you sure to perform this operation? ',
      addNewWithoutSave: 'This form data has not been saved, are you sure to perform this operation? '
    }
  },
  notification: {
    title: 'Warning',
    success: {
      title: 'Success',
      body: 'Operation succeeded',
      body_13: 'Operation is successful, waiting for approval'
    },
    error: {
      title: 'Error',
      body: 'Operation failed'
    },
    loginFailed: {
      title: 'Error',
      body: 'Login failed: <br>Wrong username or password'
    }
  },
  table: {
    title: 'Title',
    id: 'ID',
    actions: 'Actions',
    batchEdit: {
      beforeSelectedNumText: '',
      afterSelectedNumText: ' records selected ',
      openDialogButton: 'BATCH EDITOR',
      noSelectedData: 'No data selected. Please choose rows you want to update. ',
      dialogTitle: 'Batch Editor',
      dialogTipsBeforeNum: 'Apply the following changes to all above ',
      dialogTipsAfterNum: ' records: ',
      dialogTipsEqual: ' equals ',
      dialogNoInputWarn: 'Please input value',
      dialogChooseUpdateColumn: 'Select...',
      dialogConfirmButton: 'CONFIRM',
      dialogCancelButton: 'CANCEL',
      dialogSecondConfirm: 'Are you sure to perform this operation? ',
      dialogMessageTitle: 'Confirmation',
      successMessageTitle: 'Success',
      successMessageBody: 'Operation succeeded'
    }
  },
  shopmatch: {
    noMoreShopMatched: 'No more similar outlets Mapped.',
    searchText: 'You can try to input keyword to search more outlets (seperate with space for multiple keywords):',
    searchPlaceholder: 'Outlet code / name / address',
    searchShopMatchConfirm: 'Are you sure to map this outlet?',
    searchResultTipsBeforeNum: '',
    searchResultTipsAfterNum: ' records will be shown at most',
    searchResultBeforeNum: 'Search Result: ',
    searchResultAfterNum: ' records. ',
    noSelectedData: 'No data selected. Please choose rows you want to update. ',
    noSelectedShop: 'Please choose the mapped outlet.'
  },
  productmatch: {
    noMoreProductMatched: 'No more similar products mapped.',
    searchText: 'You can try to input keyword to search more products (seperate with space for multiple keywords):',
    searchPlaceholder: 'Product code / name / spec',
    searchProductMatchConfirm: 'Are you sure to map this product?',
    searchResultTipsBeforeNum: '',
    searchResultTipsAfterNum: ' records will be shown at most',
    searchResultBeforeNum: 'Search Result: ',
    searchResultAfterNum: ' records. ',
    noSelectedData: 'No data selected. Please choose rows you want to update. ',
    noSelectedProduct: 'Please choose the mapped product.',
    noSelectedProductUnit: 'Please choose the mapped product unit.',
    noProductUnitExchange: 'No unit exchange.'
  },
  distributorshopmatch: {
    reverse: {
      title: 'Calculate data to be reversed',
      distributorShop: 'Distributor Outlet',
      originalShop: 'Original Mapped Outlet',
      newShop: 'New Mapped Outlet',
      startDate: 'Reverse Start Date',
      endDate: 'Reverse End Date',
      all: 'All',
      uptonow: 'Up To Now'
    },
    billType: {
      label: 'Bill Type',
      amount: 'Amount',
      minDate: 'Min Date',
      maxDate: 'Max Date',
      sales: 'Sales'
    }
  },
  distributorproductmatch: {
    reverse: {
      title: 'Calculate data to be reversed',
      distributorProduct: 'Distributor Product',
      originalProduct: 'Original Mapped Product',
      newProduct: 'New Mapped Product',
      startDate: 'Reverse Start Date',
      endDate: 'Reverse End Date',
      all: 'All',
      uptonow: 'Up To Now'
    },
    billtype: {
      label: 'Bill Type',
      amount: 'Amount',
      minDate: 'Min Date',
      maxDate: 'Max Date',
      purchase: 'Purchase',
      sales: 'Sales',
      stock: 'Stock',
      stockIn: 'Other Stock In',
      stockOut: 'Other Stock Out'
    }
  },
  report: {
    mode: {
      table: 'Table',
      chart: 'Chart',
      chartType11: 'Common Line Chart',
      chartType12: 'Stacked Line Chart',
      chartType13: 'Area Chart',
      chartType21: 'Common Bar Chart',
      chartType22: 'Stacked Bar Chart',
      chartType23: 'Horizontal Bar Chart',
      chartType31: 'Pie Chart'
    },
    selectChartType: 'Select Chart Type',
    selectXAxisFields: 'Select X Axis Dimension',
    selectYAxisFields: 'Select Y Axis Dimension',
    selectSeriesFields: 'Select Metric(s)',
    percentage: 'Percentage'
  },
  reportEditor: {
    dialogTitle: 'Report Editor',
    chooseGroupby: 'Select Groupby Field(s)',
    chooseSelect: 'Select Display Field(s)',
    unchecked: 'Unchecked',
    checked: 'Checked',
    dialogMessageNoChecked: 'Please Select Field(s)',
    dialogConfirmButton: 'Confirm',
    dialogCancelButton: 'Cancel',
    dialogMessageTitle: 'Confirm',
    successMessageTitle: 'Success',
    successMessageBody: 'Operation succeeded'
  },
  rules: {
    login: {
      username: {
        required: 'Please input username'
      },
      password: {
        required: 'Please input password'
      }
    },
    common: {
      required: 'This item is required',
      maxlength10: 'Max length is 10',
      maxlength20: 'Max length is 20',
      maxlength50: 'Max length is 50',
      maxlength100: 'Max length is 100',
      maxlength200: 'Max length is 200',
      maxlength500: 'Max length is 500',
      cannotEmpty: 'The required item can not be empty',
      largerThan0: 'The number must be larger than 0',
      cannotDuplicated: 'The item can not be duplicated',
      mustbe: 'must be',
      uploadExceed_1: 'You can upload {1} picture for {0}',
      uploadExceed_2: 'You can upload {1} pictures for {0}'
    }
  },
  common: {
    info: 'Info',
    delete_confirm: 'Do you want to delete?',
    yes: 'Yes',
    no: 'No',
    download: 'Download',
    fileName: 'File Name',
    fileSize: 'File Size',
    download_confirm: 'Download?'
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name (default excel-list)'
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name (default file)'
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading PDF.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  dashboard: {
    panel_group: {
      uploaded_distributors: '# of Uploaded',
      purchase_amount_mtd: 'Purchase Amount MTD',
      purchase_mtd: 'Purchase MTD',
      current_month_target: 'Target of the month',
      target_completion_rate: 'Target Completion Rate',
      mom: 'MOM',
      yoy: 'YOY',
      stock: 'Stock',
      cases: 'cases',
      turn_over_days: 'Turn Over Days',
      sale_amount_mtd: 'Sales Amount MTD',
      sale_mtd: 'Sale MTD'
    },
    stock_days_bar_chart: {
      stock_standard_amount: 'Stock Standard Amount',
      stock_turn_over_days: 'Stock Turn Over Days'
    },
    shop_rank_table: {
      terminal_sales: 'Terminal Sales'
    },
    product_rank_table: {
      product_sales: 'Product Sales'
    },
    return_goods_line_chart: {
      product_return_trend: 'Product Return Trend'
    },
    uploadmonitor: {
      item1: '# of Total',
      item2: '# of Uploaded',
      item3: '# of To Upload',
      item4: '# of Not Upload > 3 days'
    }
  },
  importFile: {
    importFile: 'Import File',
    downloadTemplate: 'Download Template',
    pleaseSelect: 'Please Select...',
    importData: 'Import Data',
    confirm: {
      title: 'Info',
      confirmButton: 'Confirm',
      confirmButtonSee: 'See Detail',
      cancelButton: 'Navigate [Export Manage] Module'
    },
    importSuccess: 'Import Successful',
    importTimeout: 'The data import has exceeded {0} seconds and the system is in asynchronous processing. Please refresh the page later to view the import results or go to: System Settings-Export Management View',
    importFail: 'Import Fail'
  },
  exportFile: {
    confirm: {
      title: 'Info',
      confirmButton: 'Confirm',
      confirmButtonSee: 'See Detail',
      cancelButton: 'Navigate [Export Manage] Module'
    },
    exportSuccess: 'Import Successful',
    exportTimeout: 'The export of this file has exceeded {0} seconds, and the system is in asynchronous processing. You can download it directly in the menu: System Settings-Export Management.',
    exportRowTimeout: 'The number of file export records this time has exceeded {0}, and the system is in asynchronous processing. You can download it directly in the menu: System Settings-Export Management.',
    exportFail: 'Export Fail'
  }
};