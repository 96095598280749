import Mock from 'mockjs';
var List_en = [];
var List_zh = [];
var count = 200;
// const language = store.getters.language

for (var i = 0; i < count; i++) {
  List_en.push(Mock.mock({
    c_id: '@increment',
    b_shop_name: '@title(5, 10)',
    b_region: '@region',
    b_province: '@province',
    b_city: '@city',
    'b_shop_level|1': ['1', '2', '3'],
    c_create_time: '@datetime'
  }));
  List_zh.push(Mock.mock({
    c_id: '@increment',
    b_shop_name: '@ctitle(5, 10)',
    b_region: '@region',
    b_province: '@province',
    b_city: '@city',
    'b_shop_level|1': ['级别1', '级别2', '级别3'],
    c_create_time: '@datetime'
  }));
}
export default [
  // {
  //   url: '/shop/query',
  //   type: 'post',
  //   response: config => {
  //     const { b_shop_level, b_shop_name, page = 1, limit = 10, sort, language } = config.body
  //     const List = (language === 0  || language==='0' ? List_en : List_zh)
  //     let mockList = List.filter(item => {
  //       if (b_shop_level && item.b_shop_level.indexOf(b_shop_level) < 0) return false
  //       if (b_shop_name && item.b_shop_name.indexOf(b_shop_name) < 0) return false
  //       return true
  //     })

  //     if (sort === '-c_id') {
  //       mockList = mockList.reverse()
  //     }

  //     const pageList = mockList.filter((item, index) => index < limit * page && index >= limit * (page - 1))

  //     return {
  //       total: mockList.length,
  //       items: pageList
  //     }
  //   }
  // },
  // {
  //   url: '/shop',
  //   method: 'get',
  //   response: config => {
  //     const { id } = config.query
  //     for (const shop of List_en) {
  //       if (shop.c_id+'' === id+'') {
  //         return {
  //           code: 200,
  //           data: shop
  //         }
  //       }
  //     }
  //     return {
  //       code: 200,
  //       data: List_en[0]
  //     }
  //   }
  // },
  // {
  //   url: '/shop',
  //   type: 'post',
  //   response: _ => {
  //     return {
  //       code: 200,
  //       data: 'success'
  //     }
  //   }
  // },

  // {
  //   url: '/shop',
  //   type: 'put',
  //   response: _ => {
  //     return {
  //       code: 200,
  //       data: 'success'
  //     }
  //   }
  // },

  // {
  //   url: '/shop',
  //   type: 'delete',
  //   response: _ => {
  //     return {
  //       code: 200,
  //       data: 'success'
  //     }
  //   }
  // }
];