import "core-js/modules/es6.regexp.match";
import _objectSpread from "/var/lib/jenkins/workspace/gmi-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from '@/styles/variables.scss';
import { generateTitle } from '@/utils/i18n';
import { deepClone } from '@/utils';
import { match } from "../../../api/shopmatch";
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  data: function data() {
    return {
      filterText: '',
      routes: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routes', 'sidebar'])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  watch: {
    filterText: function filterText(val) {
      var _this = this;
      var newRoutes = deepClone(this.$store.getters.permission_routes);
      if (val != '') {
        newRoutes = newRoutes.filter(function (n) {
          return _this.match(n, val);
        });
      }
      this.routes = newRoutes;
    }
  },
  created: function created() {
    this.routes = this.$store.getters.permission_routes;
    // console.log(this.routes)
  },
  methods: {
    match: function match(item, val) {
      var _this2 = this;
      if (Array.isArray(item)) {
        return false;
      }
      if (item.meta && item.meta.title && (item.hidden === false || item.meta.icon)) {
        var text = this.generateTitle(item.meta.title);
        // console.log(text+':'+text.indexOf(val)+item.hidden)
        if (text.toUpperCase().indexOf(val.toUpperCase()) > -1) {
          // console.log(text+': true')
          return true;
        }
      }
      if (item.children && item.children.length > 0) {
        item.children = item.children.filter(function (n) {
          return _this2.match(n, val);
        });
        if (item.children.length > 0) {
          return true;
        }
      }
      return false;
    },
    generateTitle: generateTitle
  }
};