import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
import app from "./modules/app";
import permission from "./modules/permission";
import settings from "./modules/settings";
import user from "./modules/user";
import tagsView from "./modules/tagsView";
import tableDataDictionary from "./modules/tableDataDictionary";
import codeDataDictionary from "./modules/codeDataDictionary";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    permission: permission,
    settings: settings,
    user: user,
    tagsView: tagsView,
    tableDataDictionary: tableDataDictionary,
    codeDataDictionary: codeDataDictionary
  },
  getters: getters
});
export default store;