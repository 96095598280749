import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  username: function username(state) {
    return state.user.username;
  },
  name: function name(state) {
    return state.user.name;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  clientId: function clientId(state) {
    return state.user.clientId;
  },
  clientName: function clientName(state) {
    return state.user.clientName;
  },
  baseClientId: function baseClientId(state) {
    return state.user.baseClientId;
  },
  dataDictClientId: function dataDictClientId(state) {
    return state.user.dataDictClientId;
  },
  showCopyright: function showCopyright(state) {
    return state.user.showCopyright;
  },
  logoUrl: function logoUrl(state) {
    return state.user.logoUrl;
  },
  distributorId: function distributorId(state) {
    return state.user.distributorId;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  listData: function listData(state) {
    return state.listData.data;
  },
  routeCodes: function routeCodes(state) {
    return state.user.routeCodes;
  },
  tableDataDictionary: function tableDataDictionary(state) {
    return state.tableDataDictionary;
  },
  codeDataDictionary: function codeDataDictionary(state) {
    return state.codeDataDictionary;
  },
  cachedListQuerys: function cachedListQuerys(state) {
    return state.listQuerys.cachedListQuerys;
  },
  hasShopAuditPrivilege: function hasShopAuditPrivilege(state) {
    return state.user.hasShopAuditPrivilege;
  }
};
export default getters;