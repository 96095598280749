import "core-js/modules/es6.regexp.replace";
/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','user']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

// export const common = {}
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutesGM = [{
  path: '/master',
  component: Layout,
  name: 'index_master',
  redirect: 'noRedirect',
  meta: {
    title: 'master_data',
    icon: 'component',
    code: 'master'
  },
  alwaysShow: true,
  children: [{
    path: 'user',
    name: 'user',
    redirect: 'index',
    component: function component() {
      return import('@/views/user/index');
    },
    meta: {
      title: 'gm_user',
      breadcrumb: false,
      code: 'master.user'
    },
    children: [{
      path: 'role',
      name: 'role',
      redirect: 'index',
      component: function component() {
        return import('@/views/role/index');
      },
      meta: {
        title: 'gm_role',
        breadcrumb: false,
        code: 'master.role'
      },
      children: [{
        path: 'list',
        name: 'role_list',
        component: function component() {
          return import('@/views/role/list/index');
        },
        meta: {
          title: 'gm_role_list',
          code: 'master.role.list'
        },
        hidden: false
      }, {
        path: 'add',
        // name: 'role_add',
        component: function component() {
          return import('@/views/role/edit/index');
        },
        meta: {
          title: 'gm_role_add',
          noCache: true,
          activeMenu: '/master/role/list',
          breadcrumb: false,
          code: 'master.role.add'
        },
        hidden: true
      }, {
        path: 'edit/:id',
        // name: 'role_edit',
        component: function component() {
          return import('@/views/role/edit/index');
        },
        meta: {
          title: 'gm_role_edit',
          noCache: true,
          activeMenu: '/master/role/list',
          breadcrumb: false,
          code: 'master.role.edit'
        },
        hidden: true
      }, {
        path: 'delete',
        // name: 'role_delete',
        component: function component() {
          return import('@/views/role/list/index');
        },
        meta: {
          code: 'master.role.delete'
        },
        hidden: true
      }, {
        path: 'batch',
        // name: 'role_batch',
        component: function component() {
          return import('@/views/role/list/index');
        },
        meta: {
          code: 'master.role.batch'
        },
        hidden: true
      }, {
        path: 'download',
        // name: 'role_download',
        component: function component() {
          return import('@/views/role/list/index');
        },
        meta: {
          code: 'master.role.download'
        },
        hidden: true
      }]
    }, {
      path: 'position',
      name: 'position',
      redirect: 'index',
      component: function component() {
        return import('@/views/position/index');
      },
      meta: {
        title: 'gm_position',
        breadcrumb: false,
        code: 'master.position'
      },
      children: [{
        path: 'list',
        name: 'position_list',
        component: function component() {
          return import('@/views/position/list/index');
        },
        meta: {
          title: 'gm_position_list',
          code: 'master.position.list'
        },
        hidden: false
      }, {
        path: 'add',
        // name: 'position_add',
        component: function component() {
          return import('@/views/position/edit/index');
        },
        meta: {
          title: 'gm_position_add',
          noCache: true,
          activeMenu: '/master/position/list',
          breadcrumb: false,
          code: 'master.position.add'
        },
        hidden: true
      }, {
        path: 'edit/:id',
        // name: 'position_edit',
        component: function component() {
          return import('@/views/position/edit/index');
        },
        meta: {
          title: 'gm_position_edit',
          noCache: true,
          activeMenu: '/master/position/list',
          breadcrumb: false,
          code: 'master.position.edit'
        },
        hidden: true
      }, {
        path: 'delete',
        // name: 'position_delete',
        component: function component() {
          return import('@/views/position/list/index');
        },
        meta: {
          code: 'master.position.delete'
        },
        hidden: true
      }, {
        path: 'batch',
        // name: 'position_batch',
        component: function component() {
          return import('@/views/position/list/index');
        },
        meta: {
          code: 'master.position.batch'
        },
        hidden: true
      }, {
        path: 'download',
        // name: 'position_download',
        component: function component() {
          return import('@/views/position/list/index');
        },
        meta: {
          code: 'master.position.download'
        },
        hidden: true
      }, {
        path: 'import-data',
        name: 'import-data-position',
        component: function component() {
          return import('@/views/import-data/list/index');
        },
        meta: {
          title: 'gm_import_data',
          code: 'master.position.import_data'
        },
        hidden: true
      }]
    }, {
      path: 'list',
      name: 'user_list',
      component: function component() {
        return import('@/views/user/list/index');
      },
      meta: {
        title: 'gm_user_list',
        code: 'master.user.list'
      },
      hidden: false
    }, {
      path: 'add',
      // name: 'user_add',
      component: function component() {
        return import('@/views/user/edit/index');
      },
      meta: {
        title: 'gm_user_add',
        noCache: true,
        activeMenu: '/master/user/list',
        breadcrumb: false,
        code: 'master.user.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      // name: 'user_edit',
      component: function component() {
        return import('@/views/user/edit/index');
      },
      meta: {
        title: 'gm_user_edit',
        noCache: true,
        activeMenu: '/master/user/list',
        breadcrumb: false,
        code: 'master.user.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      // name: 'user_delete',
      component: function component() {
        return import('@/views/user/list/index');
      },
      meta: {
        code: 'master.user.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      // name: 'user_batch',
      component: function component() {
        return import('@/views/user/list/index');
      },
      meta: {
        code: 'master.user.batch'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'user_download',
      component: function component() {
        return import('@/views/user/list/index');
      },
      meta: {
        code: 'master.user.download'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-user',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'gm_import_data',
        code: 'master.user.import_data'
      },
      hidden: true
    }]
  }, {
    path: 'organization',
    redirect: 'noRedirect',
    name: 'organization',
    component: function component() {
      return import('@/views/organization/index');
    },
    meta: {
      title: 'gm_organization',
      code: 'master.organization'
    },
    alwaysShow: true,
    children: [{
      path: 'list',
      name: 'organization_list',
      component: function component() {
        return import('@/views/organization/list/index');
      },
      meta: {
        title: 'gm_organization_list',
        code: 'master.organization.list'
      },
      hidden: false
    }, {
      path: 'add',
      // name: 'organization_add',
      component: function component() {
        return import('@/views/organization/edit/index');
      },
      meta: {
        title: 'gm_organization_add',
        noCache: true,
        activeMenu: '/master/organization/list',
        breadcrumb: false,
        code: 'master.organization.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      // name: 'organization_edit',
      component: function component() {
        return import('@/views/organization/edit/index');
      },
      meta: {
        title: 'gm_organization_edit',
        noCache: true,
        activeMenu: '/master/organization/list',
        breadcrumb: false,
        code: 'master.organization.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      // name: 'organization_delete',
      component: function component() {
        return import('@/views/organization/list/index');
      },
      meta: {
        code: 'master.organization.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      // name: 'organization_batch',
      component: function component() {
        return import('@/views/organization/list/index');
      },
      meta: {
        code: 'master.organization.batch'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'organization_download',
      component: function component() {
        return import('@/views/organization/list/index');
      },
      meta: {
        code: 'master.organization.download'
      },
      hidden: true
    }, {
      path: 'tree_index',
      name: 'organization_tree',
      component: function component() {
        return import('@/views/organization/tree/index');
      },
      meta: {
        title: 'gm_organization_tree',
        activeMenu: '/master/organization/tree',
        code: 'master.organization.tree'
      },
      hidden: false
    }, {
      path: 'tree_add',
      // name: 'organization_add',
      component: function component() {
        return import('@/views/organization/tree/index');
      },
      meta: {
        title: 'gm_organization_add',
        noCache: true,
        activeMenu: '/master/organization/tree',
        breadcrumb: false,
        code: 'master.organization.add'
      },
      hidden: true
    }, {
      path: 'tree_edit/:id',
      // name: 'organization_edit',
      component: function component() {
        return import('@/views/organization/tree/index');
      },
      meta: {
        title: 'gm_organization_edit',
        noCache: true,
        activeMenu: '/master/organization/tree',
        breadcrumb: false,
        code: 'master.organization.edit'
      },
      hidden: true
    }, {
      path: 'tree_delete',
      // name: 'organization_delete',
      component: function component() {
        return import('@/views/organization/tree/index');
      },
      meta: {
        code: 'master.organization.delete'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-organization',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'gm_import_data',
        code: 'master.organization.import_data'
      },
      hidden: true
    }]
  }, {
    path: 'city',
    redirect: 'noRedirect',
    name: 'city',
    component: function component() {
      return import('@/views/city/index');
    },
    meta: {
      title: 'gm_city',
      code: 'master.city'
    },
    alwaysShow: true,
    children: [{
      path: 'list',
      name: 'city_list',
      component: function component() {
        return import('@/views/city/list/index');
      },
      meta: {
        title: 'gm_city_list',
        code: 'master.city.list'
      },
      hidden: false
    }, {
      path: 'add',
      // name: 'city_add',
      component: function component() {
        return import('@/views/city/edit/index');
      },
      meta: {
        title: 'gm_city_add',
        noCache: true,
        activeMenu: '/master/city/list',
        breadcrumb: false,
        code: 'master.city.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      // name: 'city_edit',
      component: function component() {
        return import('@/views/city/edit/index');
      },
      meta: {
        title: 'gm_city_edit',
        noCache: true,
        activeMenu: '/master/city/list',
        breadcrumb: false,
        code: 'master.city.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      // name: 'city_delete',
      component: function component() {
        return import('@/views/city/list/index');
      },
      meta: {
        code: 'master.city.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      // name: 'city_batch',
      component: function component() {
        return import('@/views/city/list/index');
      },
      meta: {
        code: 'master.city.batch'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'city_download',
      component: function component() {
        return import('@/views/city/list/index');
      },
      meta: {
        code: 'master.city.download'
      },
      hidden: true
    }, {
      path: 'tree_index',
      name: 'city_tree',
      component: function component() {
        return import('@/views/city/tree/index');
      },
      meta: {
        title: 'gm_city_tree',
        activeMenu: '/master/city/tree',
        code: 'master.city.tree'
      },
      hidden: false
    }, {
      path: 'tree_add',
      // name: 'city_add',
      component: function component() {
        return import('@/views/city/tree/index');
      },
      meta: {
        title: 'gm_city_add',
        noCache: true,
        activeMenu: '/master/city/tree',
        breadcrumb: false,
        code: 'master.city.add'
      },
      hidden: true
    }, {
      path: 'tree_edit/:id',
      // name: 'city_edit',
      component: function component() {
        return import('@/views/city/tree/index');
      },
      meta: {
        title: 'gm_city_edit',
        noCache: true,
        activeMenu: '/master/city/tree',
        breadcrumb: false,
        code: 'master.city.edit'
      },
      hidden: true
    }, {
      path: 'tree_delete',
      // name: 'city_delete',
      component: function component() {
        return import('@/views/city/tree/index');
      },
      meta: {
        code: 'master.city.delete'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-city',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'gm_import_data',
        code: 'master.city.import_data'
      },
      hidden: true
    }]
  }, {
    path: 'product',
    name: 'product',
    redirect: 'gm-mercury-product',
    component: function component() {
      return import('@/views/product/index');
    },
    meta: {
      title: 'gm_product',
      breadcrumb: false,
      code: 'master.product'
    },
    children: [{
      path: 'gm-mercury-product',
      name: 'gm_mercury_product',
      redirect: 'list',
      component: function component() {
        return import('@/views/gm-mercury-product/index');
      },
      meta: {
        title: 'gm_mercury_product',
        breadcrumb: false,
        code: 'master.gm_mercury_product'
      },
      children: [{
        path: 'list',
        name: 'gm_mercury_product_list',
        component: function component() {
          return import('@/views/gm-mercury-product/list/index');
        },
        meta: {
          title: 'gm_mercury_product',
          code: 'master.gm_mercury_product'
        },
        hidden: false
      }, {
        path: 'download',
        component: function component() {
          return import('@/views/gm-mercury-product/list/index');
        },
        meta: {
          code: 'master.gm_mercury_product'
        },
        hidden: true
      }]
    }, {
      path: 'productprice',
      name: 'productprice',
      redirect: 'list',
      component: function component() {
        return import('@/views/productprice/index');
      },
      meta: {
        title: 'gm_productprice',
        breadcrumb: false,
        code: 'master.productprice'
      },
      children: [{
        path: 'list',
        name: 'productprice_list',
        component: function component() {
          return import('@/views/productprice/list/index');
        },
        meta: {
          title: 'gm_productprice_list',
          code: 'master.productprice.list'
        },
        hidden: false
      }, {
        path: 'add',
        component: function component() {
          return import('@/views/productprice/edit/index');
        },
        meta: {
          title: 'gm_productprice_add',
          noCache: true,
          activeMenu: '/master/productprice/list',
          breadcrumb: false,
          code: 'master.productprice.add'
        },
        hidden: true
      }, {
        path: 'edit/:id',
        component: function component() {
          return import('@/views/productprice/edit/index');
        },
        meta: {
          title: 'gm_productprice_edit',
          noCache: true,
          activeMenu: '/master/productprice/list',
          breadcrumb: false,
          code: 'master.productprice.edit'
        },
        hidden: true
      }, {
        path: 'delete',
        component: function component() {
          return import('@/views/productprice/list/index');
        },
        meta: {
          code: 'master.productprice.delete'
        },
        hidden: true
      }, {
        path: 'batch',
        component: function component() {
          return import('@/views/productprice/list/index');
        },
        meta: {
          code: 'master.productprice.batch'
        },
        hidden: true
      }, {
        path: 'download',
        component: function component() {
          return import('@/views/productprice/list/index');
        },
        meta: {
          code: 'master.productprice.download'
        },
        hidden: true
      }, {
        path: 'import-data',
        name: 'import-data-productprice',
        component: function component() {
          return import('@/views/import-data/list/index');
        },
        meta: {
          title: 'gm_import_data',
          code: 'master.productprice.edit'
        },
        hidden: true
      }]
    }, {
      path: 'productunitexchange',
      name: 'productunitexchange',
      redirect: 'list',
      component: function component() {
        return import('@/views/productunitexchange/index');
      },
      meta: {
        title: 'gm_productunitexchange',
        breadcrumb: false,
        code: 'master.productunitexchange'
      },
      children: [{
        path: 'list',
        name: 'productunitexchange_list',
        component: function component() {
          return import('@/views/productunitexchange/list/index');
        },
        meta: {
          title: 'gm_productunitexchange_list',
          code: 'master.productunitexchange.list'
        },
        hidden: false
      }, {
        path: 'add',
        component: function component() {
          return import('@/views/productunitexchange/edit/index');
        },
        meta: {
          title: 'gm_productunitexchange_add',
          noCache: true,
          activeMenu: '/master/productunitexchange/list',
          breadcrumb: false,
          code: 'master.productunitexchange.add'
        },
        hidden: true
      }, {
        path: 'edit/:id',
        component: function component() {
          return import('@/views/productunitexchange/edit/index');
        },
        meta: {
          title: 'gm_productunitexchange_edit',
          noCache: true,
          activeMenu: '/master/productunitexchange/list',
          breadcrumb: false,
          code: 'master.productunitexchange.edit'
        },
        hidden: true
      }, {
        path: 'delete',
        component: function component() {
          return import('@/views/productunitexchange/list/index');
        },
        meta: {
          code: 'master.productunitexchange.delete'
        },
        hidden: true
      }, {
        path: 'batch',
        component: function component() {
          return import('@/views/productunitexchange/list/index');
        },
        meta: {
          code: 'master.productunitexchange.batch'
        },
        hidden: true
      }, {
        path: 'download',
        component: function component() {
          return import('@/views/productunitexchange/list/index');
        },
        meta: {
          code: 'master.productunitexchange.download'
        },
        hidden: true
      }, {
        path: 'import-data',
        name: 'import-data-productunitexchange',
        component: function component() {
          return import('@/views/import-data/list/index');
        },
        meta: {
          title: 'gm_import_data',
          code: 'master.productunitexchange.edit'
        },
        hidden: true
      }, {
        path: 'units/:id',
        component: function component() {
          return import('@/views/productunitexchange/list/units');
        },
        meta: {
          title: 'gm_productunitexchange_units',
          noCache: true,
          activeMenu: '/master/productunitexchange/list',
          breadcrumb: false,
          code: 'master.productunitexchange'
        },
        hidden: true
      }]
    }, {
      path: 'list',
      name: 'product_list',
      component: function component() {
        return import('@/views/product/list/index');
      },
      meta: {
        title: 'gm_product_list',
        code: 'master.product.list'
      },
      hidden: false
    }, {
      path: 'add',
      component: function component() {
        return import('@/views/product/edit/index');
      },
      meta: {
        title: 'gm_product_add',
        noCache: true,
        activeMenu: '/master/product/list',
        breadcrumb: false,
        code: 'master.product.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/product/edit/index');
      },
      meta: {
        title: 'gm_product_edit',
        noCache: true,
        activeMenu: '/master/product/list',
        breadcrumb: false,
        code: 'master.product.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      component: function component() {
        return import('@/views/product/list/index');
      },
      meta: {
        code: 'master.product.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      component: function component() {
        return import('@/views/product/list/index');
      },
      meta: {
        code: 'master.product.batch'
      },
      hidden: true
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/product/list/index');
      },
      meta: {
        code: 'master.product.download'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-product',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'import_data',
        code: 'master.product.import_data'
      },
      hidden: true
    }, {
      path: 'sap-product',
      name: 'sap_product',
      redirect: 'list',
      component: function component() {
        return import('@/views/sapproduct/index');
      },
      meta: {
        title: 'gm_sapproduct',
        breadcrumb: false,
        code: 'master.sapproduct'
      },
      children: [{
        path: 'list',
        name: 'sapproduct_list',
        component: function component() {
          return import('@/views/sapproduct/list/index');
        },
        meta: {
          title: 'gm_sapproduct_list',
          code: 'master.sapproduct.list'
        },
        hidden: false
      }, {
        path: 'add',
        component: function component() {
          return import('@/views/sapproduct/edit/index');
        },
        meta: {
          title: 'gm_sapproduct_add',
          noCache: true,
          activeMenu: '/master/sap-product/list',
          breadcrumb: false,
          code: 'master.sapproduct.add'
        },
        hidden: true
      }, {
        path: 'edit/:id',
        component: function component() {
          return import('@/views/sapproduct/edit/index');
        },
        meta: {
          title: 'gm_sapproduct_edit',
          noCache: true,
          activeMenu: '/master/sap-product/list',
          breadcrumb: false,
          code: 'master.sapproduct.edit'
        },
        hidden: true
      }, {
        path: 'download',
        component: function component() {
          return import('@/views/sapproduct/list/index');
        },
        meta: {
          code: 'master.sapproduct.download'
        },
        hidden: true
      }, {
        path: 'import-data',
        name: 'import_data_sapproduct',
        component: function component() {
          return import('@/views/import-data/list/index');
        },
        meta: {
          title: 'import_data',
          code: 'master.sapproduct.edit'
        },
        hidden: true
      }]
    }]
  }, {
    path: 'gm_client',
    name: 'gm_client',
    redirect: 'index',
    component: function component() {
      return import('@/views/shop/index');
    },
    meta: {
      title: 'gm_client',
      breadcrumb: false,
      code: 'master.gm_client'
    },
    children: [{
      path: 'sap-shop',
      name: 'sap_shop',
      redirect: 'list',
      component: function component() {
        return import('@/views/sapshop/index');
      },
      meta: {
        title: 'gm_sapshop',
        breadcrumb: false,
        code: 'master.sapshop'
      },
      children: [{
        path: 'list',
        name: 'sapshop_list',
        component: function component() {
          return import('@/views/sapshop/list/index');
        },
        meta: {
          title: 'gm_sapshop_list',
          code: 'master.sapshop.list'
        },
        hidden: false
      }, {
        path: 'add',
        component: function component() {
          return import('@/views/sapshop/edit/index');
        },
        meta: {
          title: 'gm_sapshop_add',
          noCache: true,
          activeMenu: '/master/sap-shop/list',
          breadcrumb: false,
          code: 'master.sapshop.add'
        },
        hidden: true
      }, {
        path: 'edit/:id',
        component: function component() {
          return import('@/views/sapshop/edit/index');
        },
        meta: {
          title: 'gm_sapshop_edit',
          noCache: true,
          activeMenu: '/master/sap-shop/list',
          breadcrumb: false,
          code: 'master.sapshop.edit'
        },
        hidden: true
      }, {
        path: 'download',
        component: function component() {
          return import('@/views/sapshop/list/index');
        },
        meta: {
          code: 'master.sapshop.download'
        },
        hidden: true
      }, {
        path: 'import-data',
        name: 'import-data-sapshop',
        component: function component() {
          return import('@/views/import-data/list/index');
        },
        meta: {
          title: 'gm_import_data',
          code: 'master.sapshop.edit'
        },
        hidden: true
      }]
    }, {
      path: 'distributor',
      name: 'distributor',
      redirect: 'index',
      component: function component() {
        return import('@/views/distributor/index');
      },
      meta: {
        title: 'gm_distributor',
        breadcrumb: false,
        code: 'master.distributor'
      },
      children: [{
        path: 'list',
        name: 'distributor_list',
        component: function component() {
          return import('@/views/distributor/list/index');
        },
        meta: {
          title: 'gm_distributor_list',
          code: 'master.distributor.list'
        },
        hidden: false
      }, {
        path: 'add',
        // name: 'distributor_add',
        component: function component() {
          return import('@/views/distributor/edit/index');
        },
        meta: {
          title: 'gm_distributor_add',
          noCache: true,
          activeMenu: '/master/distributor/list',
          breadcrumb: false,
          code: 'master.distributor.add'
        },
        hidden: true
      }, {
        path: 'edit/:id',
        // name: 'distributor_edit',
        component: function component() {
          return import('@/views/distributor/edit/index');
        },
        meta: {
          title: 'gm_distributor_edit',
          noCache: true,
          activeMenu: '/master/distributor/list',
          breadcrumb: false,
          code: 'master.distributor.edit'
        },
        hidden: true
      }, {
        path: 'delete',
        // name: 'distributor_delete',
        component: function component() {
          return import('@/views/distributor/list/index');
        },
        meta: {
          code: 'master.distributor.delete'
        },
        hidden: true
      }, {
        path: 'batch',
        // name: 'distributor_batch',
        component: function component() {
          return import('@/views/distributor/list/index');
        },
        meta: {
          code: 'master.distributor.batch'
        },
        hidden: true
      }, {
        path: 'download',
        // name: 'distributor_download',
        component: function component() {
          return import('@/views/distributor/list/index');
        },
        meta: {
          code: 'master.distributor.download'
        },
        hidden: true
      }, {
        path: 'import-data',
        name: 'import-data-distributor',
        component: function component() {
          return import('@/views/import-data/list/index');
        },
        meta: {
          title: 'gm_import_data',
          code: 'master.distributor.import_data'
        },
        hidden: true
      }, {
        path: 'distributorchange',
        name: 'distributorchange',
        redirect: 'index',
        component: function component() {
          return import('@/views/distributorchange/index');
        },
        meta: {
          title: 'gm_distributorchange',
          breadcrumb: false,
          code: 'master.distributorchange'
        },
        hidden: true,
        children: [{
          path: 'list',
          name: 'distributorchange_list',
          component: function component() {
            return import('@/views/distributorchange/list/index');
          },
          meta: {
            title: 'gm_distributorchange_list',
            code: 'master.distributorchange.list'
          },
          hidden: true
        }, {
          path: 'add',
          // name: 'distributorchange_add',
          component: function component() {
            return import('@/views/distributorchange/edit/index');
          },
          meta: {
            title: 'gm_distributorchange_add',
            noCache: true,
            activeMenu: '/master/distributorchange/list',
            breadcrumb: false,
            code: 'master.distributorchange.add'
          },
          hidden: true
        }, {
          path: 'edit/:id',
          // name: 'distributorchange_edit',
          component: function component() {
            return import('@/views/distributorchange/edit/index');
          },
          meta: {
            title: 'gm_distributorchange_edit',
            noCache: true,
            activeMenu: '/master/distributorchange/list',
            breadcrumb: false,
            code: 'master.distributorchange.edit'
          },
          hidden: true
        }, {
          path: 'delete',
          // name: 'distributorchange_delete',
          component: function component() {
            return import('@/views/distributorchange/list/index');
          },
          meta: {
            code: 'master.distributorchange.delete'
          },
          hidden: true
        }]
      }]
    }]
  }, {
    path: 'shop',
    name: 'shop',
    redirect: 'index',
    component: function component() {
      return import('@/views/shop/index');
    },
    meta: {
      title: 'gm_shop',
      breadcrumb: false,
      code: 'master.shop'
    },
    children: [{
      path: 'list',
      name: 'shop_list',
      component: function component() {
        return import('@/views/shop/list/index');
      },
      meta: {
        title: 'gm_shop_list',
        code: 'master.shop.list'
      },
      hidden: false
    }, {
      path: 'add',
      component: function component() {
        return import('@/views/shop/edit/index');
      },
      meta: {
        title: 'gm_shop_add',
        noCache: true,
        activeMenu: '/master/shop/list',
        breadcrumb: false,
        code: 'master.shop.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/shop/edit/index');
      },
      meta: {
        title: 'gm_shop_edit',
        noCache: true,
        activeMenu: '/master/shop/list',
        breadcrumb: false,
        code: 'master.shop.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      component: function component() {
        return import('@/views/shop/list/index');
      },
      meta: {
        code: 'master.shop.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      component: function component() {
        return import('@/views/shop/list/index');
      },
      meta: {
        code: 'master.shop.batch'
      },
      hidden: true
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/shop/list/index');
      },
      meta: {
        code: 'master.shop.download'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-shop',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'gm_import_data',
        code: 'master.shop.import_data'
      },
      hidden: true
    }, {
      path: 'audit/list',
      name: 'shop_audit_list',
      component: function component() {
        return import('@/views/shop/audit/list');
      },
      meta: {
        title: 'gm_shop_audit',
        code: 'master.shop'
      },
      hidden: true
    }, {
      path: 'audit/:id',
      //name: 'shop_audit_audit',
      component: function component() {
        return import('@/views/shop/audit/audit');
      },
      meta: {
        title: 'gm_shop_audit',
        noCache: true,
        breadcrumb: false,
        code: 'master.shop'
      },
      hidden: true
    }, {
      path: 'sap-mdm-shop-match',
      name: 'sap_mdm_shop_match',
      redirect: 'list',
      component: function component() {
        return import('@/views/sap-mdm-shop-match/index');
      },
      meta: {
        title: 'gm_sap_mdm_shop_match',
        breadcrumb: false,
        code: 'master.sap_mdm_shop_match'
      },
      children: [{
        path: 'list',
        name: 'sapshop_list_mdm_shop_match',
        component: function component() {
          return import('@/views/sap-mdm-shop-match/list/index');
        },
        meta: {
          title: 'gm_sap_mdm_shop_match',
          code: 'master.sap_mdm_shop_match.list'
        },
        hidden: false
      }, {
        path: 'download',
        component: function component() {
          return import('@/views/sap-mdm-shop-match/list/index');
        },
        meta: {
          code: 'master.sap_mdm_shop_match.download'
        },
        hidden: true
      }, {
        path: 'import-data',
        name: 'import-data-sap-mdm-shop-match',
        component: function component() {
          return import('@/views/import-data/list/index');
        },
        meta: {
          title: 'gm_import_data',
          code: 'master.sap_mdm_shop_match.import'
        },
        hidden: true
      }]
    }, {
      path: 'gm-channel-region-province-mapping',
      name: 'gm_channel_region_province_mapping',
      redirect: 'index',
      component: function component() {
        return import('@/views/gm-channel-region-province-mapping/index');
      },
      meta: {
        title: 'gm_channel_region_province_mapping',
        breadcrumb: false,
        code: 'master.shop.gm_channel_region_province_mapping'
      },
      children: [{
        path: 'list',
        name: 'gm_channel_region_province_mapping_list',
        component: function component() {
          return import('@/views/gm-channel-region-province-mapping/list/index');
        },
        meta: {
          title: 'gm_channel_region_province_mapping_list',
          code: 'master.gm_channel_region_province_mapping.list'
        },
        hidden: false
      }, {
        path: 'add',
        component: function component() {
          return import('@/views/gm-channel-region-province-mapping/edit/index');
        },
        meta: {
          title: 'gm_channel_region_province_mapping_add',
          noCache: true,
          activeMenu: '/master/gm-channel-region-province-mapping/list',
          breadcrumb: false,
          code: 'master.shop.gm_channel_region_province_mapping.add'
        },
        hidden: true
      }, {
        path: 'edit/:id',
        component: function component() {
          return import('@/views/gm-channel-region-province-mapping/edit/index');
        },
        meta: {
          title: 'gm_channel_region_province_mapping_edit',
          noCache: true,
          activeMenu: '/master/gm-channel-region-province-mapping/list',
          breadcrumb: false,
          code: 'master.shop.gm_channel_region_province_mapping.edit'
        },
        hidden: true
      }, {
        path: 'delete',
        component: function component() {
          return import('@/views/gm-channel-region-province-mapping/list/index');
        },
        meta: {
          code: 'master.shop.gm_channel_region_province_mapping.delete'
        },
        hidden: true
      }, {
        path: 'download',
        component: function component() {
          return import('@/views/gm-channel-region-province-mapping/list/index');
        },
        meta: {
          code: 'master.shop.gm_channel_region_province_mapping.download'
        },
        hidden: true
      }]
    }, {
      path: 'gm-org-chaingroup-shoptype-mapping',
      name: 'gm_org_chaingroup_shoptype_mapping',
      redirect: 'index',
      component: function component() {
        return import('@/views/gm-org-chaingroup-shoptype-mapping/index');
      },
      meta: {
        title: 'gm_org_chaingroup_shoptype_mapping',
        breadcrumb: false,
        code: 'master.shop.gm_org_chaingroup_shoptype_mapping'
      },
      children: [{
        path: 'list',
        name: 'gm_org_chaingroup_shoptype_mapping_list',
        component: function component() {
          return import('@/views/gm-org-chaingroup-shoptype-mapping/list/index');
        },
        meta: {
          title: 'gm_org_chaingroup_shoptype_mapping_list',
          code: 'master.gm_org_chaingroup_shoptype_mapping.list'
        },
        hidden: false
      }, {
        path: 'add',
        component: function component() {
          return import('@/views/gm-org-chaingroup-shoptype-mapping/edit/index');
        },
        meta: {
          title: 'gm_org_chaingroup_shoptype_mapping_add',
          noCache: true,
          activeMenu: '/master/gm-org-chaingroup-shoptype-mapping/list',
          breadcrumb: false,
          code: 'master.shop.gm_org_chaingroup_shoptype_mapping.add'
        },
        hidden: true
      }, {
        path: 'edit/:id',
        component: function component() {
          return import('@/views/gm-org-chaingroup-shoptype-mapping/edit/index');
        },
        meta: {
          title: 'gm_org_chaingroup_shoptype_mapping_edit',
          noCache: true,
          activeMenu: '/master/gm-org-chaingroup-shoptype-mapping/list',
          breadcrumb: false,
          code: 'master.shop.gm_org_chaingroup_shoptype_mapping.edit'
        },
        hidden: true
      }, {
        path: 'delete',
        component: function component() {
          return import('@/views/gm-org-chaingroup-shoptype-mapping/list/index');
        },
        meta: {
          code: 'master.shop.gm_org_chaingroup_shoptype_mapping.delete'
        },
        hidden: true
      }, {
        path: 'download',
        component: function component() {
          return import('@/views/gm-org-chaingroup-shoptype-mapping/list/index');
        },
        meta: {
          code: 'master.shop.gm_org_chaingroup_shoptype_mapping.download'
        },
        hidden: true
      }]
    }]
  }, {
    path: 'gm-sap-file-load',
    name: 'gm_sap_file_load',
    redirect: 'list',
    component: function component() {
      return import('@/views/gm-sap-file-load/index');
    },
    meta: {
      title: 'gm_sap_file_load',
      breadcrumb: false,
      code: 'master.gm_sap_file_load'
    },
    children: [{
      path: 'list',
      name: 'gm_sap_file_load_list',
      component: function component() {
        return import('@/views/gm-sap-file-load/list/index');
      },
      meta: {
        title: 'gm_sap_file_load',
        code: 'master.gm_sap_file_load'
      },
      hidden: false
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/gm-sap-file-load/list/index');
      },
      meta: {
        code: 'master.gm_sap_file_load'
      },
      hidden: true
    }]
  }, {
    path: 'clientperiod',
    name: 'clientperiod',
    redirect: 'index',
    component: function component() {
      return import('@/views/clientperiod/index');
    },
    meta: {
      title: 'client_period',
      breadcrumb: false,
      code: 'master.clientperiod'
    },
    children: [{
      path: 'list',
      name: 'client_period_list',
      component: function component() {
        return import('@/views/clientperiod/list/index');
      },
      meta: {
        title: 'client_period_list',
        code: 'master.clientperiod.list'
      },
      hidden: false
    }, {
      path: 'add',
      // name: 'clientperiod_add',
      component: function component() {
        return import('@/views/clientperiod/edit/index');
      },
      meta: {
        title: 'client_period_add',
        noCache: true,
        activeMenu: '/master/clientperiod/list',
        breadcrumb: false,
        code: 'master.clientperiod.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      // name: 'clientperiod_edit',
      component: function component() {
        return import('@/views/clientperiod/edit/index');
      },
      meta: {
        title: 'client_period_edit',
        noCache: true,
        activeMenu: '/master/clientperiod/list',
        breadcrumb: false,
        code: 'master.clientperiod.edit'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'clientperiod_download',
      component: function component() {
        return import('@/views/clientperiod/list/index');
      },
      meta: {
        code: 'master.clientperiod.download'
      },
      hidden: true
    }]
  }, {
    path: 'client-portal-upload-schedule',
    name: 'client-portal-upload-schedule',
    redirect: 'index',
    component: function component() {
      return import('@/views/client-portal-upload-schedule/index');
    },
    meta: {
      title: 'client_portal_upload_schedule',
      breadcrumb: false,
      code: 'master.client_portal_upload_schedule'
    },
    children: [{
      path: 'list',
      name: 'client_portal_upload_schedule_list',
      component: function component() {
        return import('@/views/client-portal-upload-schedule/list/index');
      },
      meta: {
        title: 'client_portal_upload_schedule_list',
        code: 'master.client_portal_upload_schedule.list'
      },
      hidden: false
    }, {
      path: 'add',
      component: function component() {
        return import('@/views/client-portal-upload-schedule/edit/index');
      },
      meta: {
        title: 'client_portal_upload_schedule_add',
        noCache: true,
        activeMenu: '/master/client-portal-upload-schedule/list',
        breadcrumb: false,
        code: 'master.client_portal_upload_schedule.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/client-portal-upload-schedule/edit/index');
      },
      meta: {
        title: 'client_portal_upload_schedule_edit',
        noCache: true,
        activeMenu: '/master/client-portal-upload-schedule/list',
        breadcrumb: false,
        code: 'master.client_portal_upload_schedule.edit'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'client-portal-upload-schedule_download',
      component: function component() {
        return import('@/views/client-portal-upload-schedule/list/index');
      },
      meta: {
        code: 'master.client_portal_upload_schedule.download'
      },
      hidden: true
    }]
  }]
}, {
  path: '/sys',
  component: Layout,
  name: 'system_setting',
  redirect: 'noRedirect',
  meta: {
    title: 'system_setting',
    icon: 'setting',
    code: 'system'
  },
  alwaysShow: true,
  children: [{
    path: '/api-log',
    name: 'api_log',
    // redirect: 'index',
    component: function component() {
      return import('@/views/api-log/index');
    },
    meta: {
      title: 'api_log',
      breadcrumb: true,
      code: 'system.apilog'
    },
    hidden: false
  }, {
    path: '/todo/index4',
    name: 'd',
    // redirect: 'index',
    component: function component() {
      return import('@/views/todo/index');
    },
    meta: {
      title: 'todo4',
      breadcrumb: false,
      code: 'todo'
    },
    hidden: false
  }, {
    path: 'resource',
    name: 'resource',
    redirect: 'list',
    component: function component() {
      return import('@/views/resource/index');
    },
    meta: {
      title: 'resource',
      code: 'system.resource'
    },
    alwaysShow: true,
    children: [{
      path: 'list',
      name: 'resource_list',
      component: function component() {
        return import('@/views/resource/list/index');
      },
      meta: {
        title: 'resource_list',
        code: 'system.resource.list'
      },
      hidden: false
    }, {
      path: 'add',
      component: function component() {
        return import('@/views/resource/edit/index');
      },
      meta: {
        title: 'resource_add',
        noCache: true,
        activeMenu: '/sys/resource/list',
        breadcrumb: false,
        code: 'system.resource.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/resource/edit/index');
      },
      meta: {
        title: 'resource_edit',
        noCache: true,
        activeMenu: '/sys/resource/list',
        breadcrumb: false,
        code: 'system.resource.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      component: function component() {
        return import('@/views/resource/list/index');
      },
      meta: {
        code: 'system.resource.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      component: function component() {
        return import('@/views/resource/list/index');
      },
      meta: {
        code: 'system.resource.batch'
      },
      hidden: true
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/resource/list/index');
      },
      meta: {
        code: 'system.resource.download'
      },
      hidden: true
    }, {
      path: 'tree_index',
      name: 'resource_tree',
      component: function component() {
        return import('@/views/resource/tree/index');
      },
      meta: {
        title: 'resource_tree',
        activeMenu: '/sys/resource/tree',
        code: 'system.resource.tree'
      },
      hidden: false
    }, {
      path: 'tree_add',
      // name: 'resource_add',
      component: function component() {
        return import('@/views/resource/tree/index');
      },
      meta: {
        title: 'resource_add',
        noCache: true,
        activeMenu: '/sys/resource/tree',
        breadcrumb: false,
        code: 'system.resource.add'
      },
      hidden: true
    }, {
      path: 'tree_edit/:id',
      // name: 'resource_edit',
      component: function component() {
        return import('@/views/resource/tree/index');
      },
      meta: {
        title: 'resource_edit',
        noCache: true,
        activeMenu: '/sys/resource/tree',
        breadcrumb: false,
        code: 'system.resource.edit'
      },
      hidden: true
    }, {
      path: 'tree_delete',
      // name: 'resource_delete',
      component: function component() {
        return import('@/views/resource/tree/index');
      },
      meta: {
        code: 'system.resource.delete'
      },
      hidden: true
    }]
  }, {
    path: 'data-template',
    name: 'data-template',
    redirect: 'list',
    component: function component() {
      return import('@/views/data-template/index');
    },
    meta: {
      title: 'data_template',
      breadcrumb: false,
      code: 'system.datatemplate'
    },
    children: [{
      path: 'list',
      name: 'data_template_list',
      component: function component() {
        return import('@/views/data-template/list/index');
      },
      meta: {
        title: 'data_template_list',
        code: 'system.datatemplate'
      },
      hidden: false
    }, {
      path: 'detail',
      name: 'detail',
      component: function component() {
        return import('@/views/data-template/detail/index');
      },
      meta: {
        title: 'data_template_detail',
        code: 'system.datatemplate'
      },
      hidden: true
    }]
  }, {
    path: 'export-file',
    name: 'export-file',
    redirect: 'list',
    component: function component() {
      return import('@/views/export-file/index');
    },
    meta: {
      title: 't_export_file_code',
      breadcrumb: false,
      code: 'system.exportfile'
    },
    children: [{
      path: 'list',
      name: 'list_exportfile',
      component: function component() {
        return import('@/views/export-file/list/index');
      },
      meta: {
        title: 't_export_file_code',
        code: 'system.exportfile'
      },
      hidden: false
    }]
  }, {
    path: 'report-subscribe',
    name: 'report-subscribe',
    redirect: 'list',
    component: function component() {
      return import('@/views/report-subscribe/index');
    },
    meta: {
      title: 't_report_subscribe_code',
      breadcrumb: false,
      code: 'system.report_subscribe'
    },
    children: [{
      path: 'list',
      name: 'list_report_subscribe',
      component: function component() {
        return import('@/views/report-subscribe/list/index');
      },
      meta: {
        title: 't_report_subscribe_code',
        code: 'system.report_subscribe'
      },
      hidden: false
    }, {
      path: 'add',
      component: function component() {
        return import('@/views/report-subscribe/edit/index');
      },
      meta: {
        title: 'client_add',
        noCache: true,
        activeMenu: '/app/report-subscribe/list',
        breadcrumb: false,
        code: 'app.report-subscribe'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/report-subscribe/edit/index');
      },
      meta: {
        title: 'client_edit',
        noCache: true,
        activeMenu: '/app/report-subscribe/list',
        breadcrumb: false,
        code: 'app.report-subscribe'
      },
      hidden: true
    }, {
      path: 'run',
      name: 'run',
      component: function component() {
        return import('@/views/report-subscribe/run/index');
      },
      meta: {
        title: 't_report_subscribe_run_code',
        code: 'system.report_subscribe_run'
      },
      hidden: true
    }]
  }, {
    path: 'prop-option',
    name: 'prop-option',
    redirect: 'noRedirect',
    alwaysShow: true,
    component: function component() {
      return import('@/views/prop-option/index');
    },
    meta: {
      title: 'data_dictionary_manage',
      breadcrumb: false,
      code: 'system.data_dictionary_manage'
    },
    children: [{
      path: 'data-dictionary',
      name: 'data_dictionary',
      component: function component() {
        return import('@/views/datadictionary/index');
      },
      meta: {
        title: 'data_dictionary_option',
        code: 'system.data_dictionary_option'
      },
      hidden: false
    }, {
      path: 'prop-option-index',
      name: 'prop-option-index',
      component: function component() {
        return import('@/views/prop-option/detail/index');
      },
      meta: {
        title: 'data_dictionary_option',
        code: 'system.data_dictionary_option'
      },
      hidden: true
    }, {
      path: 'client-prop-index',
      name: 'client-prop-index',
      component: function component() {
        return import('@/views/client-prop/detail/index');
      },
      meta: {
        title: 'data_dictionary_prop',
        code: 'system.data_dictionary_prop'
      },
      hidden: false
    }]
  }]
}, {
  path: '/app',
  component: Layout,
  name: 'appmanager',
  redirect: 'noRedirect',
  meta: {
    title: 'app_manager',
    icon: 'excel',
    code: 'app'
  },
  alwaysShow: true,
  children: [{
    path: 'data-center',
    name: 'data-center',
    redirect: 'client-config',
    hidden: true,
    component: function component() {
      return import('@/views/data-center/index');
    },
    meta: {
      title: 't_client_project_config_code',
      breadcrumb: false,
      code: 'app.distributor_app_code'
    },
    children: [{
      path: 'client-config',
      name: 'client-config',
      component: function component() {
        return import('@/views/data-center/client/detail/index');
      },
      meta: {
        title: 't_client_project_config_code',
        code: 'app.distributor_app_code'
      },
      hidden: true
    }, {
      path: 'distributor-config',
      name: 'distributor-config',
      component: function component() {
        return import('@/views/data-center/distributor/detail/index');
      },
      meta: {
        title: 'distributor_app_code',
        code: 'app.distributor_app_code'
      },
      hidden: true
    }]
  }, {
    path: 'client',
    name: 'client',
    redirect: 'list-config',
    component: function component() {
      return import('@/views/client/index');
    },
    meta: {
      title: 'client',
      breadcrumb: false,
      code: 'app.client'
    },
    hidden: false,
    children: [{
      path: 'list',
      name: 'client_list',
      component: function component() {
        return import('@/views/client/list/index');
      },
      meta: {
        title: 'client_list',
        code: 'app.client'
      },
      hidden: true
    }, {
      path: 'list-config',
      name: 'client_list_config',
      component: function component() {
        return import('@/views/client/list/index');
      },
      meta: {
        title: 'client_list',
        code: 'app.client'
      },
      hidden: false
    }, {
      path: 'add',
      component: function component() {
        return import('@/views/client/edit/index');
      },
      meta: {
        title: 'client_add',
        noCache: true,
        activeMenu: '/app/client/list',
        breadcrumb: false,
        code: 'app.client'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/client/edit/index');
      },
      meta: {
        title: 'client_edit',
        noCache: true,
        activeMenu: '/app/client/list',
        breadcrumb: false,
        code: 'app.client'
      },
      hidden: true
    }, {
      path: 'delete',
      component: function component() {
        return import('@/views/client/list/index');
      },
      meta: {
        code: 'app.client'
      },
      hidden: true
    }, {
      path: 'batch',
      component: function component() {
        return import('@/views/client/list/index');
      },
      meta: {
        code: 'app.client'
      },
      hidden: true
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/client/list/index');
      },
      meta: {
        code: 'app.client'
      },
      hidden: true
    }]
  }, {
    path: 'distributor-app-code',
    name: 'distributor-app-code',
    redirect: 'list',
    component: function component() {
      return import('@/views/distributor-app-code/index');
    },
    meta: {
      title: 'distributor_app_code',
      breadcrumb: false,
      code: 'app.distributor_app_code'
    },
    hidden: false,
    children: [{
      path: 'list',
      name: 'distributor_app_code_list',
      component: function component() {
        return import('@/views/distributor-app-code/list/index');
      },
      meta: {
        title: 'distributor_app_code_list',
        code: 'app.distributor_app_code'
      },
      hidden: false
    }, {
      path: 'add',
      component: function component() {
        return import('@/views/distributor-app-code/edit/index');
      },
      meta: {
        title: 'distributor_app_code_add',
        noCache: true,
        activeMenu: '/app/distributor-app-code/list',
        breadcrumb: false,
        code: 'app.distributor_app_code'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/distributor-app-code/edit/index');
      },
      meta: {
        title: 'client_edit',
        noCache: true,
        activeMenu: '/app/distributor-app-code/list',
        breadcrumb: false,
        code: 'app.distributor_app_code'
      },
      hidden: true
    }, {
      path: 'delete',
      component: function component() {
        return import('@/views/distributor-app-code/list/index');
      },
      meta: {
        code: 'app.distributor_app_code'
      },
      hidden: true
    }, {
      path: 'batch',
      component: function component() {
        return import('@/views/distributor-app-code/list/index');
      },
      meta: {
        code: 'app.distributor_app_code'
      },
      hidden: true
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/distributor-app-code/list/index');
      },
      meta: {
        code: 'app.distributor_app_code'
      },
      hidden: true
    }]
  }, {
    path: 'app-config-datacenter',
    name: 'app-config-datacenter',
    redirect: 'list',
    component: function component() {
      return import('@/views/app-config-datacenter/index');
    },
    hidden: true,
    meta: {
      title: 'app_config_datacenter',
      breadcrumb: false,
      code: 'app.app_config_datacenter_code'
    },
    children: [{
      path: 'list',
      name: 'app_config_datacenter_code_list',
      component: function component() {
        return import('@/views/app-config-datacenter/list/index');
      },
      meta: {
        title: 'app_config_datacenter_code_list',
        code: 'app.app_config_datacenter_code'
      },
      hidden: true
    }, {
      path: 'add',
      component: function component() {
        return import('@/views/app-config-datacenter/edit/index');
      },
      meta: {
        title: 'app_config_datacenter_code_add',
        noCache: true,
        activeMenu: '/app/distributor-app-code/list',
        breadcrumb: false,
        code: 'app.app_config_datacenter_code'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/app-config-datacenter/edit/index');
      },
      meta: {
        title: 'client_edit',
        noCache: true,
        activeMenu: '/app/app-config-datacenter/list',
        breadcrumb: false,
        code: 'app.app_config_datacenter_code'
      },
      hidden: true
    }, {
      path: 'delete',
      component: function component() {
        return import('@/views/app-config-datacenter/list/index');
      },
      meta: {
        code: 'app.app_config_datacenter_code'
      },
      hidden: true
    }, {
      path: 'batch',
      component: function component() {
        return import('@/views/app-config-datacenter/list/index');
      },
      meta: {
        code: 'app.app_config_datacenter_code'
      },
      hidden: true
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/app-config-datacenter/list/index');
      },
      meta: {
        code: 'app.app_config_datacenter_code'
      },
      hidden: true
    }]
  }, {
    path: 'general-table',
    name: 'general-table',
    redirect: 'list',
    component: function component() {
      return import('@/views/general-table/index');
    },
    meta: {
      title: function title(item) {
        var path = item.path;
        return path.replace(/.*\/general-table\/|\/list.*/img, '').replace(/-/img, '_') + '_code';
      },
      breadcrumb: false,
      code: 'app.generate_table_code'
    },
    hidden: true,
    children: [{
      path: ':tableName/list',
      name: 'list-general-table',
      component: function component() {
        return import('@/views/general-table/list/index');
      },
      meta: {
        title: function title(item) {
          var path = item.path;
          return path.replace(/.*\/general-table\/|\/list.*/img, '').replace(/-/img, '_') + '_code_list';
        },
        code: 'app.generate_table_code'
      },
      hidden: true
    }, {
      path: ':tableName/add',
      name: 'add',
      component: function component() {
        return import('@/views/general-table/edit/index');
      },
      meta: {
        title: function title(item) {
          var path = item.path;
          return path.replace(/.*\/general-table\/|\/add.*/img, '').replace(/-/img, '_') + '_code_add';
        },
        noCache: true,
        activeMenu: '/app/general-table/list',
        breadcrumb: false,
        code: 'app.generate_table_code'
      },
      hidden: true
    }, {
      path: ':tableName/edit/:id',
      name: 'edit',
      component: function component() {
        return import('@/views/general-table/edit/index');
      },
      meta: {
        title: function title(item) {
          var path = item.path;
          return path.replace(/.*\/general-table\/|\/edit.*/img, '').replace(/-/img, '_') + '_code_edit';
        },
        noCache: true,
        activeMenu: '/app/general-table/list',
        breadcrumb: false,
        code: 'app.generate_table_code'
      },
      hidden: true
    }, {
      path: ':tableName/delete',
      name: 'delete',
      component: function component() {
        return import('@/views/general-table/list/index');
      },
      meta: {
        code: 'app.generate_table_code'
      },
      hidden: true
    }, {
      path: ':tableName/batch',
      name: 'batch',
      component: function component() {
        return import('@/views/general-table/list/index');
      },
      meta: {
        code: 'app.generate_table_code'
      },
      hidden: true
    }, {
      path: ':tableName/download',
      name: 'download',
      component: function component() {
        return import('@/views/general-table/list/index');
      },
      meta: {
        code: 'app.generate_table_code'
      },
      hidden: true
    }]
  }, {
    path: '/todo/index6',
    name: 'clientfield',
    // redirect: 'index',
    component: function component() {
      return import('@/views/todo/index');
    },
    meta: {
      title: 'todo6',
      breadcrumb: false,
      code: 'todo'
    },
    hidden: false
  }, {
    path: '/todo/index14',
    name: 'a',
    // redirect: 'index',
    component: function component() {
      return import('@/views/todo/index');
    },
    meta: {
      title: 'todo14',
      breadcrumb: false,
      code: 'todo'
    },
    hidden: false
  }, {
    path: 'install-check-compare',
    redirect: 'noRedirect',
    name: 'install-check-compare',
    component: function component() {
      return import('@/views/app-acceptance/install-check-compare/index');
    },
    meta: {
      title: 'install_check_compare',
      code: 'app.acceptance.install_check_compare'
    },
    alwaysShow: true,
    children: [{
      path: 'purchase',
      name: 'install-check-compare-purchase',
      component: function component() {
        return import('@/views/app-acceptance/install-check-compare/purchase');
      },
      meta: {
        title: 'install_check_compare_purchase',
        code: 'app.acceptance.install_check_compare.purchase'
      },
      hidden: false
    }, {
      path: 'sales',
      name: 'install-check-compare-sales',
      component: function component() {
        return import('@/views/app-acceptance/install-check-compare/sales');
      },
      meta: {
        title: 'install_check_compare_sales',
        code: 'app.acceptance.install_check_compare.sales'
      },
      hidden: false
    }, {
      path: 'stock',
      name: 'install-check-compare-stock',
      component: function component() {
        return import('@/views/app-acceptance/install-check-compare/stock');
      },
      meta: {
        title: 'install_check_compare_stock',
        code: 'app.acceptance.install_check_compare.stock'
      },
      hidden: false
    }]
  }]
}, {
  path: '/data-view-dms',
  component: Layout,
  name: 'data-view-dms',
  redirect: 'noRedirect',
  meta: {
    title: 'data_view_dms',
    icon: 'table',
    code: 'dataview.dms'
  },
  alwaysShow: true,
  children: [{
    path: 'dms-rawdata',
    redirect: 'noRedirect',
    name: 'dms-rawdata',
    component: function component() {
      return import('@/views/dms-rawdata/index');
    },
    meta: {
      title: 'dms_rawdata',
      code: 'dataview.dms.rawdata'
    },
    alwaysShow: true,
    children: [{
      path: 'dms-rawdatapurchase',
      name: 'dms-rawdatapurchase',
      component: function component() {
        return import('@/views/dms-rawdata/purchase');
      },
      meta: {
        title: 'dms_rawdatapurchase',
        code: 'dataview.dms.rawdata.purchase'
      },
      hidden: false
    }, {
      path: 'dms-rawdatasales',
      name: 'dms-rawdatasales',
      component: function component() {
        return import('@/views/dms-rawdata/sales');
      },
      meta: {
        title: 'dms_rawdatasales',
        code: 'dataview.dms.rawdata.sales'
      },
      hidden: false
    }, {
      path: 'dms-rawdatastock',
      name: 'dms-rawdatastock',
      component: function component() {
        return import('@/views/dms-rawdata/stock');
      },
      meta: {
        title: 'dms_rawdatastock',
        code: 'dataview.dms.rawdata.stock'
      },
      hidden: false
    }, {
      path: 'dms-rawdatatransfer',
      name: 'dms-rawdatatransfer',
      component: function component() {
        return import('@/views/dms-rawdata/transfer');
      },
      meta: {
        title: 'dms_rawdatatransfer',
        code: 'dataview.dms.rawdata.transfer'
      },
      hidden: false
    }, {
      path: 'dms-rawdatachange',
      name: 'dms-rawdatachange',
      component: function component() {
        return import('@/views/dms-rawdata/change');
      },
      meta: {
        title: 'dms_rawdatachange',
        code: 'dataview.dms.rawdata.change'
      },
      hidden: false
    }, {
      path: 'dms-rawdatapurchase-portal',
      name: 'dms-rawdatapurchase-portal',
      component: function component() {
        return import('@/views/dms-rawdata/purchase_portal');
      },
      meta: {
        title: 'dms_rawdatapurchase',
        code: 'dataview.dms.rawdata.purchase'
      },
      hidden: true
    }, {
      path: 'dms-rawdatasales-portal',
      name: 'dms-rawdatasales-portal',
      component: function component() {
        return import('@/views/dms-rawdata/sales_portal');
      },
      meta: {
        title: 'dms_rawdatasales',
        code: 'dataview.dms.rawdata.sales'
      },
      hidden: true
    }, {
      path: 'dms-rawdatachange-portal',
      name: 'dms-rawdatachange-portal',
      component: function component() {
        return import('@/views/dms-rawdata/change_portal');
      },
      meta: {
        title: 'dms_rawdatachange',
        code: 'dataview.dms.rawdata.change'
      },
      hidden: true
    }]
  }, {
    path: 'dms-matcheddata',
    redirect: 'noRedirect',
    name: 'dms-matcheddata',
    component: function component() {
      return import('@/views/dms-matcheddata/index');
    },
    meta: {
      title: 'dms_matcheddata',
      code: 'dataview.dms.matcheddata'
    },
    alwaysShow: true,
    children: [{
      path: 'dms-matcheddatapurchase',
      name: 'dms-matcheddatapurchase',
      component: function component() {
        return import('@/views/dms-matcheddata/purchase');
      },
      meta: {
        title: 'dms_matcheddatapurchase',
        code: 'dataview.dms.matcheddata.purchase'
      },
      hidden: false
    }, {
      path: 'dms-matcheddatasales',
      name: 'dms-matcheddatasales',
      component: function component() {
        return import('@/views/dms-matcheddata/sales');
      },
      meta: {
        title: 'dms_matcheddatasales',
        code: 'dataview.dms.matcheddata.sales'
      },
      hidden: false
    }, {
      path: 'dms-matcheddatastock',
      name: 'dms-matcheddatastock',
      component: function component() {
        return import('@/views/dms-matcheddata/stock');
      },
      meta: {
        title: 'dms_matcheddatastock',
        code: 'dataview.dms.matcheddata.stock'
      },
      hidden: false
    }, {
      path: 'dms-matcheddatatransfer',
      name: 'dms-matcheddatatransfer',
      component: function component() {
        return import('@/views/dms-matcheddata/transfer');
      },
      meta: {
        title: 'dms_matcheddatatransfer',
        code: 'dataview.dms.matcheddata.transfer'
      },
      hidden: false
    }, {
      path: 'dms-matcheddatachange',
      name: 'dms-matcheddatachange',
      component: function component() {
        return import('@/views/dms-matcheddata/change');
      },
      meta: {
        title: 'dms_matcheddatachange',
        code: 'dataview.dms.matcheddata.change'
      },
      hidden: false
    }]
  }, {
    path: 'dms-converteddata',
    redirect: 'noRedirect',
    name: 'dms-converteddata',
    component: function component() {
      return import('@/views/dms-converteddata/index');
    },
    meta: {
      title: 'dms_converteddata',
      code: 'dataview.dms.converteddata'
    },
    alwaysShow: true,
    children: [{
      path: 'dms-converteddatapurchase',
      name: 'dms-converteddatapurchase',
      component: function component() {
        return import('@/views/dms-converteddata/purchase');
      },
      meta: {
        title: 'dms_converteddatapurchase',
        code: 'dataview.dms.converteddata.purchase'
      },
      hidden: false
    }, {
      path: 'dms-converteddatasales',
      name: 'dms-converteddatasales',
      component: function component() {
        return import('@/views/dms-converteddata/sales');
      },
      meta: {
        title: 'dms_converteddatasales',
        code: 'dataview.dms.converteddata.sales'
      },
      hidden: false
    }, {
      path: 'dms-converteddatastock',
      name: 'dms-converteddatastock',
      component: function component() {
        return import('@/views/dms-converteddata/stock');
      },
      meta: {
        title: 'dms_converteddatastock',
        code: 'dataview.dms.converteddata.stock'
      },
      hidden: false
    }, {
      path: 'dms-converteddatatransfer',
      name: 'dms-converteddatatransfer',
      component: function component() {
        return import('@/views/dms-converteddata/transfer');
      },
      meta: {
        title: 'dms_converteddatatransfer',
        code: 'dataview.dms.converteddata.transfer'
      },
      hidden: false
    }, {
      path: 'dms-converteddatachange',
      name: 'dms-converteddatachange',
      component: function component() {
        return import('@/views/dms-converteddata/change');
      },
      meta: {
        title: 'dms_converteddatachange',
        code: 'dataview.dms.converteddata.change'
      },
      hidden: false
    }]
  }, {
    path: 'dms-settledata',
    redirect: 'noRedirect',
    name: 'dms-settledata',
    component: function component() {
      return import('@/views/dms-settledata/index');
    },
    meta: {
      title: 'dms_settledata',
      code: 'dataview.dms.settledata'
    },
    alwaysShow: true,
    children: [{
      path: 'dms-settledatapurchase',
      name: 'dms-settledatapurchase',
      component: function component() {
        return import('@/views/dms-settledata/purchase');
      },
      meta: {
        title: 'dms_settledatapurchase',
        code: 'dataview.dms.settledata.purchase'
      },
      hidden: false
    }, {
      path: 'dms-settledatasales',
      name: 'dms-settledatasales',
      component: function component() {
        return import('@/views/dms-settledata/sales');
      },
      meta: {
        title: 'dms_settledatasales',
        code: 'dataview.dms.settledata.sales'
      },
      hidden: false
    }, {
      path: 'dms-settledatastock',
      name: 'dms-settledatastock',
      component: function component() {
        return import('@/views/dms-settledata/stock');
      },
      meta: {
        title: 'dms_settledatastock',
        code: 'dataview.dms.settledata.stock'
      },
      hidden: false
    }, {
      path: 'dms-settledatatransfer',
      name: 'dms-settledatatransfer',
      component: function component() {
        return import('@/views/dms-settledata/transfer');
      },
      meta: {
        title: 'dms_settledatatransfer',
        code: 'dataview.dms.settledata.transfer'
      },
      hidden: false
    }, {
      path: 'dms-settledatachange',
      name: 'dms-settledatachange',
      component: function component() {
        return import('@/views/dms-settledata/change');
      },
      meta: {
        title: 'dms_settledatachange',
        code: 'dataview.dms.settledata.change'
      },
      hidden: false
    }]
  }]
}, {
  path: '/data-view-pos',
  component: Layout,
  name: 'data-view-pos',
  redirect: 'noRedirect',
  meta: {
    title: 'data_view_pos',
    icon: 'table',
    code: 'dataview.pos'
  },
  alwaysShow: true,
  children: [{
    path: 'pos-rawdata',
    redirect: 'noRedirect',
    name: 'pos-rawdata',
    component: function component() {
      return import('@/views/pos-rawdata/index');
    },
    meta: {
      title: 'pos_rawdata',
      code: 'dataview.pos.rawdata'
    },
    alwaysShow: true,
    children: [{
      path: 'pos-rawdatapurchase',
      name: 'pos-rawdatapurchase',
      component: function component() {
        return import('@/views/pos-rawdata/purchase');
      },
      meta: {
        title: 'pos_rawdatapurchase',
        code: 'dataview.pos.rawdata.purchase'
      },
      hidden: false
    }, {
      path: 'pos-rawdatasales',
      name: 'pos-rawdatasales',
      component: function component() {
        return import('@/views/pos-rawdata/sales');
      },
      meta: {
        title: 'pos_rawdatasales',
        code: 'dataview.pos.rawdata.sales'
      },
      hidden: false
    }, {
      path: 'pos-rawdatastock',
      name: 'pos-rawdatastock',
      component: function component() {
        return import('@/views/pos-rawdata/stock');
      },
      meta: {
        title: 'pos_rawdatastock',
        code: 'dataview.pos.rawdata.stock'
      },
      hidden: false
    }, {
      path: 'pos-rawdatatransfer',
      name: 'pos-rawdatatransfer',
      component: function component() {
        return import('@/views/pos-rawdata/transfer');
      },
      meta: {
        title: 'pos_rawdatatransfer',
        code: 'dataview.pos.rawdata.transfer'
      },
      hidden: false
    }, {
      path: 'pos-rawdatachange',
      name: 'pos-rawdatachange',
      component: function component() {
        return import('@/views/pos-rawdata/change');
      },
      meta: {
        title: 'pos_rawdatachange',
        code: 'dataview.pos.rawdata.change'
      },
      hidden: false
    }]
  }, {
    path: 'pos-matcheddata',
    redirect: 'noRedirect',
    name: 'pos-matcheddata',
    component: function component() {
      return import('@/views/pos-matcheddata/index');
    },
    meta: {
      title: 'pos_matcheddata',
      code: 'dataview.pos.matcheddata'
    },
    alwaysShow: true,
    children: [{
      path: 'pos-matcheddatapurchase',
      name: 'pos-matcheddatapurchase',
      component: function component() {
        return import('@/views/pos-matcheddata/purchase');
      },
      meta: {
        title: 'pos_matcheddatapurchase',
        code: 'dataview.pos.matcheddata.purchase'
      },
      hidden: false
    }, {
      path: 'pos-matcheddatasales',
      name: 'pos-matcheddatasales',
      component: function component() {
        return import('@/views/pos-matcheddata/sales');
      },
      meta: {
        title: 'pos_matcheddatasales',
        code: 'dataview.pos.matcheddata.sales'
      },
      hidden: false
    }, {
      path: 'pos-matcheddatastock',
      name: 'pos-matcheddatastock',
      component: function component() {
        return import('@/views/pos-matcheddata/stock');
      },
      meta: {
        title: 'pos_matcheddatastock',
        code: 'dataview.pos.matcheddata.stock'
      },
      hidden: false
    }, {
      path: 'pos-matcheddatatransfer',
      name: 'pos-matcheddatatransfer',
      component: function component() {
        return import('@/views/pos-matcheddata/transfer');
      },
      meta: {
        title: 'pos_matcheddatatransfer',
        code: 'dataview.pos.matcheddata.transfer'
      },
      hidden: false
    }, {
      path: 'pos-matcheddatachange',
      name: 'pos-matcheddatachange',
      component: function component() {
        return import('@/views/pos-matcheddata/change');
      },
      meta: {
        title: 'pos_matcheddatachange',
        code: 'dataview.pos.matcheddata.change'
      },
      hidden: false
    }]
  }, {
    path: 'pos-converteddata',
    redirect: 'noRedirect',
    name: 'pos-converteddata',
    component: function component() {
      return import('@/views/pos-converteddata/index');
    },
    meta: {
      title: 'pos_converteddata',
      code: 'dataview.pos.converteddata'
    },
    alwaysShow: true,
    children: [{
      path: 'pos-converteddatapurchase',
      name: 'pos-converteddatapurchase',
      component: function component() {
        return import('@/views/pos-converteddata/purchase');
      },
      meta: {
        title: 'pos_converteddatapurchase',
        code: 'dataview.pos.converteddata.purchase'
      },
      hidden: false
    }, {
      path: 'pos-converteddatasales',
      name: 'pos-converteddatasales',
      component: function component() {
        return import('@/views/pos-converteddata/sales');
      },
      meta: {
        title: 'pos_converteddatasales',
        code: 'dataview.pos.converteddata.sales'
      },
      hidden: false
    }, {
      path: 'pos-converteddatastock',
      name: 'pos-converteddatastock',
      component: function component() {
        return import('@/views/pos-converteddata/stock');
      },
      meta: {
        title: 'pos_converteddatastock',
        code: 'dataview.pos.converteddata.stock'
      },
      hidden: false
    }, {
      path: 'pos-converteddatatransfer',
      name: 'pos-converteddatatransfer',
      component: function component() {
        return import('@/views/pos-converteddata/transfer');
      },
      meta: {
        title: 'pos_converteddatatransfer',
        code: 'dataview.pos.converteddata.transfer'
      },
      hidden: false
    }, {
      path: 'pos-converteddatachange',
      name: 'pos-converteddatachange',
      component: function component() {
        return import('@/views/pos-converteddata/change');
      },
      meta: {
        title: 'pos_converteddatachange',
        code: 'dataview.pos.converteddata.change'
      },
      hidden: false
    }]
  }, {
    path: 'pos-settledata',
    redirect: 'noRedirect',
    name: 'pos-settledata',
    component: function component() {
      return import('@/views/pos-settledata/index');
    },
    meta: {
      title: 'pos_settledata',
      code: 'dataview.pos.settledata'
    },
    alwaysShow: true,
    children: [{
      path: 'pos-settledatapurchase',
      name: 'pos-settledatapurchase',
      component: function component() {
        return import('@/views/pos-settledata/purchase');
      },
      meta: {
        title: 'pos_settledatapurchase',
        code: 'dataview.pos.settledata.purchase'
      },
      hidden: false
    }, {
      path: 'pos-settledatasales',
      name: 'pos-settledatasales',
      component: function component() {
        return import('@/views/pos-settledata/sales');
      },
      meta: {
        title: 'pos_settledatasales',
        code: 'dataview.pos.settledata.sales'
      },
      hidden: false
    }, {
      path: 'pos-settledatastock',
      name: 'pos-settledatastock',
      component: function component() {
        return import('@/views/pos-settledata/stock');
      },
      meta: {
        title: 'pos_settledatastock',
        code: 'dataview.pos.settledata.stock'
      },
      hidden: false
    }, {
      path: 'pos-settledatatransfer',
      name: 'pos-settledatatransfer',
      component: function component() {
        return import('@/views/pos-settledata/transfer');
      },
      meta: {
        title: 'pos_settledatatransfer',
        code: 'dataview.pos.settledata.transfer'
      },
      hidden: false
    }, {
      path: 'pos-settledatachange',
      name: 'pos-settledatachange',
      component: function component() {
        return import('@/views/pos-settledata/change');
      },
      meta: {
        title: 'pos_settledatachange',
        code: 'dataview.pos.settledata.change'
      },
      hidden: false
    }]
  }]
}, {
  path: '/data-match',
  component: Layout,
  name: 'datamatch',
  redirect: 'noRedirect',
  meta: {
    title: 'data_match',
    icon: 'eye-open',
    code: 'datamatch'
  },
  alwaysShow: true,
  children: [{
    path: 'distributorclientbilltype',
    name: 'distributorclientbilltype',
    redirect: 'list',
    component: function component() {
      return import('@/views/distributorclientbilltype/index');
    },
    meta: {
      title: 'distributorclientbilltype',
      breadcrumb: false,
      code: 'datamatch.distributorclientbilltype'
    },
    children: [{
      path: 'list',
      name: 'distributorclientbilltype_list',
      component: function component() {
        return import('@/views/distributorclientbilltype/list/index');
      },
      meta: {
        title: 'distributorclientbilltype_list',
        activeMenu: '/data-match/distributorclientbilltype/list',
        code: 'datamatch.distributorclientbilltype.list'
      },
      hidden: false
    }, {
      path: 'add',
      component: function component() {
        return import('@/views/distributorclientbilltype/edit/index');
      },
      meta: {
        title: 'distributorclientbilltype_add',
        noCache: true,
        activeMenu: '/data-match/distributorclientbilltype/list',
        breadcrumb: false,
        code: 'datamatch.distributorclientbilltype.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/distributorclientbilltype/edit/index');
      },
      meta: {
        title: 'distributorclientbilltype_edit',
        noCache: true,
        activeMenu: '/data-match/distributorclientbilltype/list',
        breadcrumb: false,
        code: 'datamatch.distributorclientbilltype.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      component: function component() {
        return import('@/views/distributorclientbilltype/list/index');
      },
      meta: {
        code: 'datamatch.distributorclientbilltype.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      component: function component() {
        return import('@/views/distributorclientbilltype/list/index');
      },
      meta: {
        code: 'datamatch.distributorclientbilltype.batch'
      },
      hidden: true
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/distributorclientbilltype/list/index');
      },
      meta: {
        code: 'datamatch.distributorclientbilltype.download'
      },
      hidden: true
    }]
  }, {
    path: 'shopmatch',
    name: 'shopmatch',
    redirect: 'overview',
    component: function component() {
      return import('@/views/shopmatch/index');
    },
    meta: {
      title: 'shopmatch',
      breadcrumb: false,
      code: 'datamatch.shopmatch'
    },
    children: [{
      path: 'overview',
      name: 'overview-shopmatch',
      component: function component() {
        return import('@/views/shopmatch/overview/index');
      },
      meta: {
        title: 'shopmatch_overview',
        activeMenu: '/data-match/shopmatch/overview',
        breadcrumb: true,
        code: 'datamatch.shopmatch.overview'
      },
      hidden: false
    }, {
      path: 'shoplist/:id',
      name: 'shoplist',
      component: function component() {
        return import('@/views/shopmatch/shoplist/index');
      },
      meta: {
        title: 'shopmatch_shoplist',
        activeMenu: '/data-match/shopmatch/shoplist',
        breadcrumb: true,
        code: 'datamatch.shopmatch.shoplist'
      },
      hidden: true
    }]
  }, {
    path: 'shopmatch-client',
    name: 'shopmatch_client',
    // redirect: 'overview',
    component: function component() {
      return import('@/views/shopmatch/index');
    },
    meta: {
      title: 'shopmatch_client',
      breadcrumb: true,
      code: 'datamatch.shopmatch.client'
    },
    children: [{
      path: 'overview-client',
      name: 'overview_client-shopmatch',
      component: function component() {
        return import('@/views/shopmatch/overview/index');
      },
      meta: {
        title: 'shopmatch_client_overview',
        code: 'datamatch.shopmatch.client.overview'
      },
      hidden: false
    }, {
      path: 'shoplist/:id',
      name: 'shoplist_client',
      component: function component() {
        return import('@/views/shopmatch/shoplist/index');
      },
      meta: {
        title: 'shopmatch_shoplist',
        activeMenu: '/data-match/shopmatch',
        breadcrumb: false,
        code: 'datamatch.shopmatch.client.shoplist'
      },
      hidden: true
    }]
  }, {
    path: 'distributorshopmatch',
    name: 'distributorshopmatch',
    redirect: 'list',
    component: function component() {
      return import('@/views/distributorshopmatch/index');
    },
    meta: {
      title: 'distributorshopmatch',
      breadcrumb: false,
      code: 'datamatch.distributorshopmatch'
    },
    children: [{
      path: 'list',
      name: 'distributorshopmatch_list',
      component: function component() {
        return import('@/views/distributorshopmatch/list/index');
      },
      meta: {
        title: 'distributorshopmatch_list',
        activeMenu: '/data-match/distributorshopmatch/list',
        code: 'datamatch.distributorshopmatch.list'
      },
      hidden: false
    }, {
      path: 'add',
      name: 'distributorshopmatch_add',
      component: function component() {
        return import('@/views/distributorshopmatch/edit/index');
      },
      meta: {
        title: 'distributorshopmatch_add',
        noCache: true,
        activeMenu: '/data-match/distributorshopmatch/list',
        breadcrumb: false,
        code: 'datamatch.distributorshopmatch.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      name: 'distributorshopmatch_edit',
      component: function component() {
        return import('@/views/distributorshopmatch/edit/index');
      },
      meta: {
        title: 'distributorshopmatch_edit',
        noCache: true,
        activeMenu: '/data-match/distributorshopmatch/list',
        breadcrumb: false,
        code: 'datamatch.distributorshopmatch.edit'
      },
      hidden: true
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/distributorshopmatch/list/index');
      },
      meta: {
        code: 'datamatch.distributorshopmatch.download'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-distributorshopmatch',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'import_data',
        code: 'datamatch.distributorshopmatch.import_data'
      },
      hidden: true
    }]
  }, {
    path: 'productmatch',
    name: 'productmatch',
    redirect: 'overview',
    component: function component() {
      return import('@/views/productmatch/index');
    },
    meta: {
      title: 'productmatch',
      breadcrumb: false,
      code: 'datamatch.productmatch'
    },
    children: [{
      path: 'overview',
      name: 'overview-product',
      component: function component() {
        return import('@/views/productmatch/overview/index');
      },
      meta: {
        title: 'productmatch_overview',
        activeMenu: '/data-match/productmatch/overview',
        code: 'datamatch.productmatch.overview'
      },
      hidden: false
    }, {
      path: 'productlist/:id',
      name: 'productlist',
      component: function component() {
        return import('@/views/productmatch/productlist/index');
      },
      meta: {
        title: 'productmatch_productlist',
        activeMenu: '/data-match/productmatch/overview',
        breadcrumb: false,
        code: 'datamatch.productmatch.productlist'
      },
      hidden: true
    }]
  }, {
    path: 'productmatch-client',
    name: 'productmatch_client',
    redirect: 'noRedirect',
    component: function component() {
      return import('@/views/productmatch/index');
    },
    meta: {
      title: 'productmatch_client',
      breadcrumb: true,
      code: 'datamatch.productmatch.client'
    },
    children: [{
      path: 'overview-client',
      name: 'overview_client-product',
      component: function component() {
        return import('@/views/productmatch/overview/index');
      },
      meta: {
        title: 'productmatch_client_overview',
        activeMenu: '/data-match/productmatch-client/overview-client',
        code: 'datamatch.productmatch.client.overview'
      },
      hidden: false
    }, {
      path: 'productlist/:id',
      name: 'productlist_client',
      component: function component() {
        return import('@/views/productmatch/productlist/index');
      },
      meta: {
        title: 'productmatch_productlist',
        activeMenu: '/data-match/productmatch-client/overview-client',
        breadcrumb: false,
        code: 'datamatch.productmatch.productlist'
      },
      hidden: true
    }]
  }, {
    path: 'distributorproductmatch',
    name: 'distributorproductmatch',
    redirect: 'list',
    component: function component() {
      return import('@/views/distributorproductmatch/index');
    },
    meta: {
      title: 'distributorproductmatch',
      breadcrumb: false,
      code: 'datamatch.distributorproductmatch'
    },
    children: [{
      path: 'list',
      name: 'distributorproductmatch_list',
      component: function component() {
        return import('@/views/distributorproductmatch/list/index');
      },
      meta: {
        title: 'distributorproductmatch_list',
        activeMenu: '/data-match/distributorproductmatch/list',
        code: 'datamatch.distributorproductmatch.list'
      },
      hidden: false
    }, {
      path: 'add',
      name: 'distributorproductmatch_add',
      component: function component() {
        return import('@/views/distributorproductmatch/edit/index');
      },
      meta: {
        title: 'distributorproductmatch_add',
        noCache: true,
        activeMenu: '/data-match/distributorproductmatch/list',
        breadcrumb: false,
        code: 'datamatch.distributorproductmatch.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      name: 'distributorproductmatch_edit',
      component: function component() {
        return import('@/views/distributorproductmatch/edit/index');
      },
      meta: {
        title: 'distributorproductmatch_edit',
        noCache: true,
        activeMenu: '/data-match/distributorproductmatch/list',
        breadcrumb: false,
        code: 'datamatch.distributorproductmatch.edit'
      },
      hidden: true
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/distributorproductmatch/list/index');
      },
      meta: {
        code: 'datamatch.distributorproductmatch.download'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-distributorproductmatch',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'import_data',
        code: 'datamatch.distributorproductmatch.import_data'
      },
      hidden: true
    }]
  }]
}, {
  path: '/data-check',
  component: Layout,
  name: 'datacheck',
  redirect: 'noRedirect',
  meta: {
    title: 'data_check',
    icon: 'search',
    code: 'datacheck'
  },
  alwaysShow: true,
  children: [{
    path: 'duplicate-shop-check',
    name: 'duplicate_shop_check',
    component: function component() {
      return import('@/views/shop/duplicate-shop-check/index');
    },
    meta: {
      title: 'duplicate_shop_check',
      code: 'datacheck.duplicateshopcheck'
    },
    hidden: false
  }, {
    path: 'distributorbalance',
    name: 'distributorbalance',
    redirect: 'check',
    component: function component() {
      return import('@/views/distributorbalance/index');
    },
    meta: {
      title: 'distributorbalance',
      breadcrumb: false,
      code: 'datacheck.distributorbalance'
    },
    children: [{
      path: 'check',
      name: 'check',
      component: function component() {
        return import('@/views/distributorbalance/check/index');
      },
      meta: {
        title: 'distributorbalance_check',
        activeMenu: '/data-check/distributorbalance/check',
        breadcrumb: true,
        code: 'datacheck.distributorbalance'
      },
      hidden: false
    }, {
      path: 'dms-converteddatastock',
      name: 'dms-converteddatastock2',
      component: function component() {
        return import('@/views/distributorbalance/detail/stock');
      },
      meta: {
        title: 'dms_converteddatastock',
        activeMenu: '/data-check/distributorbalance/check',
        breadcrumb: true
      },
      hidden: true
    }, {
      path: 'dms-converteddatapurchase',
      name: 'dms-converteddatapurchase2',
      component: function component() {
        return import('@/views/distributorbalance/detail/purchase');
      },
      meta: {
        title: 'dms_converteddatapurchase',
        activeMenu: '/data-check/distributorbalance/check',
        breadcrumb: true
      },
      hidden: true
    }, {
      path: 'dms-converteddatasales',
      name: 'dms-converteddatasales2',
      component: function component() {
        return import('@/views/distributorbalance/detail/sales');
      },
      meta: {
        title: 'dms_converteddatasales',
        activeMenu: '/data-check/distributorbalance/check',
        breadcrumb: true
      },
      hidden: true
    }, {
      path: 'dms-converteddatatransfer',
      name: 'dms-converteddatatransfer2',
      component: function component() {
        return import('@/views/distributorbalance/detail/transfer');
      },
      meta: {
        title: 'dms_converteddatatransfer',
        activeMenu: '/data-check/distributorbalance/check',
        breadcrumb: true
      },
      hidden: true
    }, {
      path: 'dms-converteddatachange',
      name: 'dms-converteddatachange2',
      component: function component() {
        return import('@/views/distributorbalance/detail/change');
      },
      meta: {
        title: 'dms_converteddatachange',
        activeMenu: '/data-check/distributorbalance/check',
        breadcrumb: true
      },
      hidden: true
    }]
  }, {
    path: 'productmatchcheck1',
    name: 'productmatchcheck1',
    component: function component() {
      return import('@/views/productmatchcheck/check1');
    },
    meta: {
      title: 'productmatchcheck1',
      code: 'datacheck.productmatchcheck.check1'
    },
    hidden: false
  }, {
    path: 'productmatchcheck2',
    name: 'productmatchcheck2',
    component: function component() {
      return import('@/views/productmatchcheck/check2');
    },
    meta: {
      title: 'productmatchcheck2',
      code: 'datacheck.productmatchcheck.check2'
    },
    hidden: false
  }, {
    path: 'productmatchcheck3',
    name: 'productmatchcheck3',
    component: function component() {
      return import('@/views/productmatchcheck/check3');
    },
    meta: {
      title: 'productmatchcheck3',
      code: 'datacheck.productmatchcheck.check3'
    },
    hidden: false
  }, {
    path: 'productmatchcheck4',
    name: 'productmatchcheck4',
    component: function component() {
      return import('@/views/productmatchcheck/check4');
    },
    meta: {
      title: 'productmatchcheck4',
      code: 'datacheck.productmatchcheck.check4'
    },
    hidden: false
  }, {
    path: 'productmatchmarkuprate',
    name: 'productmatchmarkuprate',
    component: function component() {
      return import('@/views/productmatchcheck/markuprate');
    },
    meta: {
      title: 'productmatchmarkuprate',
      code: 'datacheck.productmatchcheck.markuprate'
    },
    hidden: false
  }]
}, {
  path: '/report',
  component: Layout,
  name: 'report',
  redirect: 'noRedirect',
  meta: {
    title: 'report',
    icon: 'chart',
    code: 'report'
  },
  alwaysShow: true,
  children: [{
    path: 'distributor-pss',
    name: 'distributor_pss',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/distributor_pss');
    },
    meta: {
      title: 'report_distributor_pss',
      breadcrumb: false,
      code: 'report.common.distributor_pss'
    },
    hidden: false
  }, {
    path: 'distributor-stock',
    name: 'distributor_stock',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/distributor_stock');
    },
    meta: {
      title: 'report_distributor_stock',
      breadcrumb: false,
      code: 'report.common.distributor_stock'
    },
    hidden: false
  }, {
    path: 'distributor-sales',
    name: 'distributor_sales',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/distributor_sales');
    },
    meta: {
      title: 'report_distributor_sales',
      breadcrumb: false,
      code: 'report.common.distributor_sales'
    },
    hidden: false
  }, {
    path: 'distributor-sales-rank',
    name: 'distributor_sales_rank',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/distributor_sales_rank');
    },
    meta: {
      title: 'report_distributor_sales_rank',
      breadcrumb: false,
      code: 'report.common.distributor_sales_rank'
    },
    hidden: false
  }, {
    path: 'product-distribution',
    name: 'product_distribution',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/product_distribution');
    },
    meta: {
      title: 'report_product_distribution',
      breadcrumb: false,
      code: 'report.common.product_distribution'
    },
    hidden: false
  }, {
    path: 'product-increase-top10',
    name: 'product_increase_top10',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/product_increase_top10');
    },
    meta: {
      title: 'report_product_sales_increase_rate',
      breadcrumb: false,
      code: 'report.common.product_increase_top10'
    },
    hidden: false
  }, {
    path: 'distributor-sales-product-top20',
    name: 'distributor_sales_product_top20',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/distributor_sales_product_top20');
    },
    meta: {
      title: 'report_distributor_sales_product_top20',
      breadcrumb: false,
      code: 'report.common.distributor_sales_product_top20'
    },
    hidden: false
  }, {
    path: 'distributor-sales-shop-top20',
    name: 'distributor_sales_shop_top20',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/distributor_sales_shop_top20');
    },
    meta: {
      title: 'report_distributor_sales_shop_top20',
      breadcrumb: false,
      code: 'report.common.distributor_sales_shop_top20'
    },
    hidden: false
  }, {
    path: 'shop-number',
    name: 'shop_number',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/shop_number');
    },
    meta: {
      title: 'report_shop_number',
      breadcrumb: false,
      code: 'report.common.shop_number'
    },
    hidden: false
  }, {
    path: 'shop-new',
    name: 'shop_new',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/shop_new');
    },
    meta: {
      title: 'report_shop_new',
      breadcrumb: false,
      code: 'report.common.shop_new'
    },
    hidden: false
  }, {
    path: 'shop-lost',
    name: 'shop_lost',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/shop_lost');
    },
    meta: {
      title: 'report_shop_lost',
      breadcrumb: false,
      code: 'report.common.shop_lost'
    },
    hidden: false
  }, {
    path: 'mdm-shop-number',
    name: 'mdm_shop_number',
    // redirect: 'index',
    component: function component() {
      return import('@/views/report/mdm_shop_number');
    },
    meta: {
      title: 'report_mdm_shop_number',
      breadcrumb: false,
      code: 'report.common.mdm.shop_number'
    },
    hidden: false
  }]
}, {
  path: '/demo',
  component: Layout,
  name: 'demo',
  redirect: 'noRedirect',
  meta: {
    title: 'demo',
    icon: 'chart',
    code: 'demo'
  },
  alwaysShow: true,
  children: [{
    path: 'demo-map-gm',
    name: 'demo-map-gm',
    // redirect: 'index',
    component: function component() {
      return import('@/views/demo-map/pop');
    },
    meta: {
      title: 'demo_map_gm',
      breadcrumb: false,
      code: 'demo.map_gm'
    },
    hidden: false
  }
  // {
  //   path: '/demo-tianyancha',
  //   name: 'demo-tianyancha',
  //   // redirect: 'index',
  //   component: () => import('@/views/tianyancha/index'),
  //   meta: { title: 'demo_tianyancha', breadcrumb: false, code: 'demo.tianyancha' },
  //   hidden: false
  // },
  ]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];