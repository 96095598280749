import Mock from 'mockjs';
var data_en = Mock.mock({
  'tables': [{
    name: 't_product',
    tableColumns: [{
      code: 'c_id',
      label: 'ID',
      width: 50
    }, {
      code: 'b_product_name',
      label: 'Product Name',
      width: 200
    }, {
      code: 'c_create_time',
      label: 'Create Time',
      width: 200
    }]
  }, {
    name: 't_shop',
    tableColumns: [{
      code: 'c_id',
      label: 'ID',
      type: 'number',
      visible: true,
      width: 50,
      sortable: true,
      fixed: true
    }, {
      code: 'b_shop_name',
      label: 'Shop Name',
      type: 'input',
      visible: true,
      width: 300,
      sortable: true,
      queryable: true,
      editable: true,
      batchEditable: true,
      fixed: true,
      linkEdit: true
    }, {
      code: 'b_region',
      label: 'Region',
      type: 'singleSelect',
      optionsCodeName: 'common.b_region_province_city',
      options: [],
      // hasChild: true,
      visible: true,
      width: 100,
      sortable: true,
      queryable: false
    }, {
      code: 'b_province',
      label: 'Province',
      type: 'singleSelect',
      optionsCodeName: 'common.b_region_province_city',
      options: [],
      hasParent: true,
      visible: true,
      width: 100,
      sortable: true,
      queryable: true,
      editable: true
    }, {
      code: 'b_city',
      label: 'City',
      type: 'singleSelect',
      optionsCodeName: 'common.b_region_province_city',
      options: [],
      hasParent: true,
      visible: true,
      width: 100,
      sortable: true,
      queryable: true,
      editable: true,
      batchEditable: true
    }, {
      code: 'b_shop_level',
      label: 'Shop Level',
      type: 'singleSelect',
      optionsCodeName: 't_shop.b_shop_level',
      options: [],
      // hasChild: false,
      visible: true,
      width: 90,
      sortable: true,
      queryable: true,
      editable: true,
      batchEditable: true
    }, {
      code: 'c_create_time',
      label: 'Create Time',
      type: 'dateTime',
      visible: true,
      width: 120,
      sortable: true,
      queryable: true,
      editable: true,
      batchEditable: true
    }]
  }, {
    name: 't_organization',
    tableColumns: [{
      code: 'c_id',
      label: 'ID',
      type: 'number',
      visible: true,
      width: 50,
      sortable: true,
      fixed: true
    }, {
      code: 'c_parent_id',
      label: 'Parent',
      type: 'treeSingleSelect',
      treeUrl: '/organization/tree',
      treeKey: 'c_id',
      treeLabel: 'b_organization_abbr',
      optionsUrl: '/organization/value-label-list',
      options: [],
      visible: true,
      width: 100,
      sortable: true,
      editable: true
    }, {
      code: 'b_organization_code',
      label: 'Organization Code',
      type: 'input',
      visible: true,
      width: 100,
      sortable: true,
      queryable: true,
      editable: true,
      fixed: true
    }, {
      code: 'b_organization_name',
      label: 'Organization Name',
      type: 'input',
      visible: true,
      width: 300,
      sortable: true,
      queryable: true,
      editable: true,
      fixed: true,
      linkEdit: true
    }, {
      code: 'b_organization_abbr',
      label: 'Organization Abbr',
      type: 'input',
      visible: true,
      width: 300,
      sortable: true,
      editable: true
    }, {
      code: 'b_organization_status',
      label: 'Status',
      type: 'singleSelect',
      optionsCodeName: 'option.common.status',
      options: [],
      visible: true,
      width: 90,
      sortable: true,
      queryable: true,
      editable: true,
      batchEditable: true
    }, {
      code: 'b_organization_address',
      label: 'Address',
      type: 'input',
      visible: true,
      width: 300,
      sortable: true,
      editable: true
    }, {
      code: 'c_rec_creator',
      label: 'Creator',
      type: 'input',
      visible: true,
      width: 120,
      sortable: true
    }, {
      code: 'c_rec_create_time',
      label: 'Create Time',
      type: 'dateTime',
      visible: true,
      width: 120,
      sortable: true
    }, {
      code: 'c_rec_revisor',
      label: 'Revisor',
      type: 'input',
      visible: true,
      width: 120,
      sortable: true
    }, {
      code: 'c_rec_revise_time',
      label: 'Revise Time',
      type: 'dateTime',
      visible: true,
      width: 120,
      sortable: true
    }]
  }]
});
var data_zh = Mock.mock({
  'tables': [{
    name: 't_product',
    tableColumns: [{
      code: 'c_id',
      label: '编号',
      width: 50
    }, {
      code: 'b_product_name',
      label: '产品名称',
      width: 200
    }, {
      code: 'c_create_time',
      label: '创建时间',
      width: 200
    }]
  }, {
    name: 't_shop',
    tableColumns: [{
      code: 'c_id',
      label: '编号',
      type: 'number',
      visible: false,
      width: 60,
      sortable: true,
      fixed: true
    }, {
      code: 'b_shop_name',
      label: '门店名称',
      type: 'input',
      visible: true,
      width: 200,
      sortable: true,
      queryable: true,
      fixed: true
    }, {
      code: 'b_shop_level',
      label: '门店等级',
      type: 'singleSelect',
      optionsCodeName: 't_shop.b_shop_level',
      visible: true,
      width: 100,
      sortable: true,
      queryable: true
    }, {
      code: 'c_create_time',
      label: '创建时间',
      type: 'dateTime',
      visible: true,
      width: 200,
      sortable: true,
      queryable: true
    }]
  }]
});
export default [{
  url: '/sys/table-data-dictionary',
  type: 'get',
  response: function response(config) {
    var language = config.query.language;
    if (language === 0 || language === '0') {
      return data_en;
    } else if (language === 1 || language === '1') {
      return data_zh;
    }
    return data_zh;
  }
}];