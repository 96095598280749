import "core-js/modules/es6.function.name";
import { login as _login, logout as _logout, getInfo as _getInfo } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
var state = {
  token: getToken(),
  username: '',
  name: '',
  avatar: '',
  roles: [],
  locale: '',
  routeCodes: [],
  clientId: -1,
  clientName: '',
  baseClientId: -1,
  dataDictClientId: -1,
  showCopyright: 1,
  logoUrl: '',
  distributorId: -1,
  hasShopAuditPrivilege: 0
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USERNAME: function SET_USERNAME(state, username) {
    state.username = username;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_LOCALE: function SET_LOCALE(state, locale) {
    state.locale = locale;
  },
  SET_ROUTECODES: function SET_ROUTECODES(state, routeCodes) {
    state.routeCodes = routeCodes;
  },
  SET_CLIENTID: function SET_CLIENTID(state, clientId) {
    state.clientId = clientId;
  },
  SET_CLIENTNAME: function SET_CLIENTNAME(state, clientName) {
    state.clientName = clientName;
  },
  SET_BASECLIENTID: function SET_BASECLIENTID(state, baseClientId) {
    state.baseClientId = baseClientId;
  },
  SET_DATADICTCLIENTID: function SET_DATADICTCLIENTID(state, dataDictClientId) {
    state.dataDictClientId = dataDictClientId;
  },
  SET_SHOWCOPYRIGHT: function SET_SHOWCOPYRIGHT(state, showCopyright) {
    state.showCopyright = showCopyright;
  },
  SET_LOGOURL: function SET_LOGOURL(state, logoUrl) {
    state.logoUrl = logoUrl;
  },
  SET_DISTRIBUTORID: function SET_DISTRIBUTORID(state, distributorId) {
    state.distributorId = distributorId;
  },
  SET_HASSHOPAUDITPRIVILEGE: function SET_HASSHOPAUDITPRIVILEGE(state, hasShopAuditPrivilege) {
    state.hasShopAuditPrivilege = hasShopAuditPrivilege;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        username: username.trim(),
        password: password
      }).then(function (response) {
        // console.log(response)
        if (response.data.code != '0') {
          reject(response.data);
        }
        commit('SET_USERNAME', username);
        var data = response.data.data;
        commit('SET_TOKEN', data.token);
        setToken(data.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _getInfo(state.token).then(function (response) {
        var data = response.data.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        var roles = data.roles,
          username = data.username,
          name = data.name,
          locale = data.locale,
          routeCodes = data.routeCodes,
          clientId = data.clientId,
          clientName = data.clientName,
          baseClientId = data.baseClientId,
          dataDictClientId = data.dataDictClientId,
          showCopyright = data.showCopyright,
          logoUrl = data.logoUrl,
          distributorId = data.distributorId,
          hasShopAuditPrivilege = data.hasShopAuditPrivilege;
        var avatar = '';
        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!');
        }
        // if (!routeCodes) {
        //   routeCodes = ['basic']
        // }
        commit('SET_ROLES', roles);
        commit('SET_NAME', name);
        commit('SET_USERNAME', username);
        commit('SET_AVATAR', avatar);
        commit('SET_LOCALE', locale);
        commit('SET_ROUTECODES', routeCodes);
        commit('SET_CLIENTID', clientId);
        commit('SET_CLIENTNAME', clientName);
        commit('SET_BASECLIENTID', baseClientId);
        commit('SET_DATADICTCLIENTID', dataDictClientId);
        commit('SET_SHOWCOPYRIGHT', showCopyright);
        commit('SET_LOGOURL', logoUrl);
        commit('SET_DISTRIBUTORID', distributorId);
        commit('SET_HASSHOPAUDITPRIVILEGE', hasShopAuditPrivilege);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state,
      dispatch = _ref3.dispatch;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        // commit('SET_LOCALE', '')
        commit('SET_ROUTECODES', []);
        commit('SET_CLIENTID', -1);
        commit('SET_CLIENTNAME', '');
        commit('SET_BASECLIENTID', -1);
        commit('SET_DATADICTCLIENTID', -1);
        commit('SET_SHOWCOPYRIGHT', 1);
        commit('SET_LOGOURL', '');
        commit('SET_DISTRIBUTORID', -1);
        commit('SET_HASSHOPAUDITPRIVILEGE', 0);
        removeToken();
        resetRouter();
        dispatch('tagsView/delAllViews', null, {
          root: true
        });
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      commit('SET_ROUTECODES', []);
      commit('SET_CLIENTID', -1);
      commit('SET_CLIENTNAME', '');
      commit('SET_BASECLIENTID', -1);
      commit('SET_DATADICTCLIENTID', -1);
      commit('SET_SHOWCOPYRIGHT', 1);
      commit('SET_LOGOURL', 1);
      commit('SET_DISTRIBUTORID', -1);
      commit('SET_HASSHOPAUDITPRIVILEGE', 0);
      removeToken();
      resolve();
    });
  },
  setNewToken: function setNewToken(_ref5, token) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', token);
      setToken(token);
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};