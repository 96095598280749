import { getTableDataDictionary as _getTableDataDictionary, refreshTableDataDictionary as _refreshTableDataDictionary } from '@/api/sys';
var state = {
  tables: JSON.parse(sessionStorage.getItem('TABLES')) || []
};
var mutations = {
  SET_TABLES: function SET_TABLES(state, tables) {
    sessionStorage.setItem('TABLES', JSON.stringify(tables));
    state.tables = tables;
  }
};
var actions = {
  getTableDataDictionary: function getTableDataDictionary(_ref, language) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _getTableDataDictionary(language).then(function (response) {
        var data = response.data.data;
        //console.log(data)
        commit('SET_TABLES', data);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  refreshTableDataDictionary: function refreshTableDataDictionary(_ref2, language) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _refreshTableDataDictionary(language).then(function (response) {
        var data = response.data.data;
        //console.log(data)
        commit('SET_TABLES', data);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  setTableDataDictionary: function setTableDataDictionary(_ref3, data) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      commit('SET_TABLES', data);
      resolve();
    }).then().catch(function (error) {
      reject(error);
    });
  },
  clearTableDataDictionary: function clearTableDataDictionary(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      commit('SET_TABLES', null);
      resolve();
    }).then().catch(function (error) {
      reject(error);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};