import { getCodeDataDictionary as _getCodeDataDictionary, refreshCodeDataDictionary as _refreshCodeDataDictionary } from '@/api/sys';
var state = {
  codes: JSON.parse(sessionStorage.getItem('CODES')) || []
};
var mutations = {
  SET_CODES: function SET_CODES(state, codes) {
    sessionStorage.setItem('CODES', JSON.stringify(codes));
    state.codes = codes;
  }
};
var actions = {
  getCodeDataDictionary: function getCodeDataDictionary(_ref, language) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _getCodeDataDictionary(language).then(function (response) {
        var data = response.data.data;
        commit('SET_CODES', data);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  refreshCodeDataDictionary: function refreshCodeDataDictionary(_ref2, language) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _refreshCodeDataDictionary(language).then(function (response) {
        var data = response.data.data;
        commit('SET_CODES', data);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  setCodeDataDictionary: function setCodeDataDictionary(_ref3, data) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      commit('SET_CODES', data);
      resolve();
    }).then().catch(function (error) {
      reject(error);
    });
  },
  clearCodeDataDictionary: function clearCodeDataDictionary(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      commit('SET_CODES', null);
      resolve();
    }).then().catch(function (error) {
      reject(error);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};