import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {};
  },
  computed: {
    size: function size() {
      return this.$store.getters.size;
    },
    sizeOptions: function sizeOptions() {
      return [{
        label: this.$t('index.element_ui_size_large'),
        value: 'default'
      }, {
        label: this.$t('index.element_ui_size_small'),
        value: 'small'
      }
      // { label: 'Mini', value: 'mini' }
      ];
    }
  },
  methods: {
    handleSetSize: function handleSetSize(size) {
      this.$ELEMENT.size = size;
      this.$store.dispatch('app/setSize', size);
      this.refreshView();
      this.$message({
        message: this.$t('index.switch_size'),
        type: 'success'
      });
    },
    refreshView: function refreshView() {
      var _this = this;
      // In order to make the cached page re-rendered
      this.$store.dispatch('tagsView/delAllCachedViews', this.$route);
      var fullPath = this.$route.fullPath;
      this.$nextTick(function () {
        _this.$router.replace({
          path: '/redirect' + fullPath
        });
      });
    }
  }
};