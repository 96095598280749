var tokens = {
  admin: {
    token: 'admin-token'
  },
  user: {
    token: 'user-token'
  }
};
var users = {
  'admin-token': {
    roles: ['admin'],
    introduction: 'I am a super administrator',
    avatar: '',
    name: 'Super Admin',
    routeCodes: []
  },
  'user-token': {
    roles: ['user'],
    introduction: 'I am an user',
    avatar: '',
    name: 'Normal User',
    routeCodes: []
  }
};
export default [
// user login
{
  url: '/user/login',
  type: 'post',
  response: function response(config) {
    var username = config.body.username;
    var token = tokens[username];

    // mock error
    if (!token) {
      return {
        code: 60204,
        message: 'Account and password are incorrect.'
      };
    }
    return token;
  }
},
// get user info
{
  url: '/user/info\.*',
  type: 'get',
  response: function response(config) {
    var token = config.query.token;
    var info = users[token];

    // mock error
    if (!info) {
      return {
        code: 50008,
        message: 'Login failed, unable to get user details.'
      };
    }
    return info;
  }
},
// user logout
{
  url: '/user/logout',
  type: 'post',
  response: function response(_) {
    // return {
    //   code: 20000,
    //   data: 'success'
    // }
    return 'success';
  }
}];