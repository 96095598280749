var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "none" } },
    [
      _c(
        "el-badge",
        { staticClass: "item", attrs: { value: 200, max: 99 } },
        [_c("svg-icon", { attrs: { "icon-class": "message" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }