import "core-js/modules/es6.array.sort";
import Mock from 'mockjs';
var List = [];
var count = 100;
// const language = store.getters.language

for (var i = 0; i < count; i++) {
  List.push(Mock.mock({
    c_id: '@integer(1,21)',
    c_parent_id: ['@integer(1,21)'],
    b_organization_code: '@title(1, 1)',
    b_organization_name: '@title(5, 10)',
    b_organization_abbr: '@title(2, 2)',
    'b_organization_status|1': ['1', '0'],
    b_organization_address: '@title(5, 10)',
    c_rec_creator: '@name',
    c_rec_create_time: '@datetime',
    c_rec_revisor: '@name',
    c_rec_revise_time: '@datetime'
  }));
}
export default [{
  url: '/organization/query',
  type: 'post',
  response: function response(config) {
    var _config$body = config.body,
      b_organization_code = _config$body.b_organization_code,
      b_organization_name = _config$body.b_organization_name,
      _config$body$page = _config$body.page,
      page = _config$body$page === void 0 ? 1 : _config$body$page,
      _config$body$limit = _config$body.limit,
      limit = _config$body$limit === void 0 ? 10 : _config$body$limit,
      sort = _config$body.sort,
      language = _config$body.language;
    var mockList = List.filter(function (item) {
      if (b_organization_code && item.b_organization_code.indexOf(b_organization_code) < 0) return false;
      if (b_organization_name && item.b_organization_name.indexOf(b_organization_name) < 0) return false;
      return true;
    });
    if (sort === '-c_id') {
      mockList = mockList.reverse();
    }
    var pageList = mockList.filter(function (item, index) {
      return index < limit * page && index >= limit * (page - 1);
    });
    return {
      total: mockList.length,
      items: pageList
    };
  }
}, {
  url: '/organization/tree',
  type: 'post',
  response: function response(config) {
    return {
      code: 200,
      data: [{
        c_id: 1,
        b_organization_abbr: 'North',
        children: [{
          c_id: 2,
          b_organization_abbr: 'Beijing',
          children: [{
            c_id: 3,
            b_organization_abbr: 'Beijing'
          }]
        }, {
          c_id: 4,
          b_organization_abbr: 'Liaoning',
          children: [{
            c_id: 5,
            b_organization_abbr: 'Shenyang'
          }, {
            c_id: 6,
            b_organization_abbr: 'Dalian'
          }, {
            c_id: 7,
            b_organization_abbr: 'Panjing'
          }]
        }]
      }, {
        c_id: 8,
        b_organization_abbr: 'East',
        children: [{
          c_id: 9,
          b_organization_abbr: 'Shanghai',
          children: [{
            c_id: 10,
            b_organization_abbr: 'Shanghai'
          }]
        }, {
          c_id: 11,
          b_organization_abbr: 'Jiangsu',
          children: [{
            c_id: 12,
            b_organization_abbr: 'Nanjing'
          }, {
            c_id: 13,
            b_organization_abbr: 'Suzhou'
          }, {
            c_id: 14,
            b_organization_abbr: 'Wuxi'
          }]
        }, {
          c_id: 15,
          b_organization_abbr: 'Zhejiang',
          children: [{
            c_id: 16,
            b_organization_abbr: 'Hangzhou'
          }, {
            c_id: 17,
            b_organization_abbr: 'Wenzhou'
          }, {
            c_id: 18,
            b_organization_abbr: 'Ningbo'
          }]
        }]
      }, {
        c_id: 19,
        b_organization_abbr: 'Central',
        children: [{
          c_id: 20,
          b_organization_abbr: 'Henan',
          children: [{
            c_id: 21,
            b_organization_abbr: 'Zhengzhou'
          }]
        }]
      }]
    };
  }
}, {
  url: '/organization/value-label-list',
  type: 'post',
  response: function response(config) {
    return {
      code: 200,
      data: [{
        value: 1,
        label: "North"
      }, {
        value: 2,
        label: "Beijing"
      }, {
        value: 3,
        label: "Beijing"
      }, {
        value: 4,
        label: "Liaoning"
      }, {
        value: 5,
        label: "Shenyang"
      }, {
        value: 6,
        label: "Dalian"
      }, {
        value: 7,
        label: "Panjing"
      }, {
        value: 8,
        label: "East"
      }, {
        value: 9,
        label: "Shanghai"
      }, {
        value: 10,
        label: "Shanghai"
      }, {
        value: 11,
        label: "Jiangsu"
      }, {
        value: 12,
        label: "Nanjing"
      }, {
        value: 13,
        label: "Suzhou"
      }, {
        value: 14,
        label: "Wuxi"
      }, {
        value: 15,
        label: "Zhejiang"
      }, {
        value: 16,
        label: "Hangzhou"
      }, {
        value: 17,
        label: "Wenzhou"
      }, {
        value: 18,
        label: "Ningbo"
      }, {
        value: 19,
        label: "Central"
      }, {
        value: 20,
        label: "Henan"
      }, {
        value: 21,
        label: "Zhengzhou"
      }]
    };
  }
}, {
  url: '/organization',
  method: 'get',
  response: function response(config) {
    console.log(config);
    var id = config.query.id;
    for (var _i = 0, _List = List; _i < _List.length; _i++) {
      var organization = _List[_i];
      if (organization.c_id + '' === id + '') {
        return {
          code: 200,
          data: organization
        };
      }
    }
    return {
      code: 200,
      data: List[0]
    };
  }
}, {
  url: '/organization',
  type: 'post',
  response: function response(_) {
    return {
      code: 200,
      data: 'success'
    };
  }
}, {
  url: '/organization',
  type: 'put',
  response: function response(_) {
    return {
      code: 200,
      data: 'success'
    };
  }
}, {
  url: '/organization',
  type: 'delete',
  response: function response(_) {
    return {
      code: 200,
      data: 'success'
    };
  }
}];