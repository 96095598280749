import request from '@/utils/request';
export function getCodeDataDictionary(language) {
  return request({
    url: '/sys/code-data-dictionary',
    method: 'get',
    params: {
      language: language
    }
  });
}
export function getTableDataDictionary(language) {
  return request({
    url: '/sys/table-data-dictionary',
    method: 'get',
    params: {
      language: language
    }
  });
}
export function refreshTableDataDictionary(language) {
  return request({
    url: '/sys/refresh-table-data-dictionary',
    method: 'get',
    params: {
      language: language
    }
  });
}
export function refreshCodeDataDictionary(language) {
  return request({
    url: '/sys/refresh-code-data-dictionary',
    method: 'get',
    params: {
      language: language
    }
  });
}