export default {
  index: {
    system_name: '',
    system_name1: '主數據管理系統',
    system_name2: '經銷商信息系統',
    hello: '您好',
    your_role: '您的角色是',
    switch_language: '已成功切換為中文簡體',
    element_ui_size_large: '大',
    element_ui_size_small: '小',
    switch_size: '已成功切換字體大小',
    shop_audit: {
      view_type1: '待处理',
      view_type2: '已处理',
      view_type3: '我发起的',
      more: '查看更多',
      add_shop: '新增门店',
      update_shop: '修改门店'
    }
  },
  route: {
    dashboard: '首頁',
    dashboard2: '首頁',
    uploadmonitor: '上傳監控',
    master_data: '主數據管理',
    system_setting: '系統設置',
    distributordata: '經銷商數據',
    organization: '組織管理',
    organization_list: '列表模式',
    organization_tree: '樹形模式',
    organization_add: '新增組織',
    organization_edit: '編輯組織',
    organization_delete: '刪除組織',
    user: '用戶管理',
    user_list: '用戶管理',
    user_add: '新增用戶',
    user_edit: '編輯用戶',
    user_delete: '刪除用戶',
    position: '職位管理',
    position_list: '職位管理',
    position_add: '新增職位',
    position_edit: '編輯職位',
    position_delete: '刪除職位',
    role: '權限角色管理',
    role_list: '權限角色管理',
    role_add: '新增權限角色',
    role_edit: '編輯權限角色',
    role_delete: '刪除權限角色',
    roleworkflow: '流程角色管理',
    roleworkflow_list: '流程角色管理',
    roleworkflow_add: '新增流程角色',
    roleworkflow_edit: '編輯流程角色',
    roleworkflow_delete: '刪除流程角色',
    resource: '權限資源管理',
    resource_list: '列表模式',
    resource_tree: '樹形模式',
    resource_add: '新增權限資源',
    resource_edit: '編輯權限資源',
    resource_delete: '刪除權限資源',
    distributor: '經銷商管理',
    distributor_list: '經銷商管理',
    distributor_add: '新增經銷商',
    distributor_edit: '編輯經銷商',
    distributor_delete: '刪除經銷商',
    distributorchange: '经销商变更',
    distributorchange_list: '经销商变更',
    distributorchange_add: '新增经销商变更',
    distributorchange_edit: '编辑经销商变更',
    distributorchange_delete: '删除经销商变更',
    product: '產品管理',
    product_list: '產品管理',
    product_add: '新增產品',
    product_edit: '編輯產品',
    product_delete: '刪除產品',
    productprice: '產品價格管理',
    productprice_list: '產品價格管理',
    productprice_add: '新增產品價格',
    productprice_edit: '編輯產品價格',
    productprice_delete: '刪除產品價格',
    productunitexchange: '產品單位換算',
    productunitexchange_list: '產品單位換算管理',
    productunitexchange_add: '新增產品單位換算',
    productunitexchange_edit: '編輯產品單位換算',
    productunitexchange_delete: '刪除產品單位換算',
    productunitexchange_units: '维护產品單位換算',
    productunitcheck: '产品单位换算检查',
    shop: '終端門店管理',
    shop_list: '終端門店管理',
    shop_add: '新增終端門店',
    shop_edit: '編輯終端門店',
    shop_delete: '刪除終端門店',
    shop_audit: '門店审批',
    client: '客戶管理',
    client_list: '客戶管理',
    client_add: '新增客戶',
    client_edit: '編輯客戶',
    client_delete: '刪除客戶',
    client_app_config_data_center: '客戶數據中心',
    t_client_project_config_code: '客户配置',
    t_client_project_config_code_list: '客户配置',
    t_client_project_config_code_add: '新增客户配置',
    t_client_project_config_code_edit: '编辑客户配置',
    t_client_project_config_code_delete: '删除客户配置',
    distributor_app_code: '客戶端配置',
    distributor_app_code_list: '客戶端配置',
    distributor_app_code_add: '添加客戶端配置',
    distributor_app_code_edit: '編輯客戶端配置',
    distributor_app_code_delete: '刪除客戶端配置',
    app_config_datacenter_code: '服務配置',
    app_config_datacenter_code_list: '服務配置',
    app_config_datacenter_code_add: '新增服務配置',
    app_config_datacenter_code_edit: '編輯服務配置',
    app_config_datacenter_code_delete: '删除服務配置',
    t_app_conf_server_datacenter_domain_code: '服務域名配置',
    t_app_conf_server_datacenter_domain_code_list: '服務域名配置',
    t_app_conf_server_datacenter_domain_code_add: '新增服務域名配置',
    t_app_conf_server_datacenter_domain_code_edit: '編輯服務域名配置',
    t_app_conf_server_datacenter_domain_code_delete: '刪除服務IP配置',
    t_app_conf_server_datacenter_ip_code: '服務IP配置',
    t_app_conf_server_datacenter_ip_code_list: '服務IP配置',
    t_app_conf_server_datacenter_ip_code_add: '新增服務IP配置',
    t_app_conf_server_datacenter_ip_code_edit: '編輯服務IP配置',
    t_app_conf_server_datacenter_ip_code_delete: '刪除服務IP配置',
    t_app_conf_server_url_code: '服務Url配置',
    t_app_conf_server_url_code_list: '服務Url配置',
    t_app_conf_server_url_code_add: '新增服務Url配置',
    t_app_conf_server_url_code_edit: '編輯服務Url配置',
    t_app_conf_server_url_code_delete: '刪除服務Url配置',
    t_app_conf_application_property_code: '应用Property配置',
    t_app_conf_application_property_code_list: '應用Property配置',
    t_app_conf_application_property_code_add: '新增應用Property配置',
    t_app_conf_application_property_code_edit: '編輯應用Property配置',
    t_app_conf_application_property_code_delete: '刪除應用Property配置',
    t_app_conf_application_code: '应用Xml配置',
    t_app_conf_application_code_list: '應用Xml配置',
    t_app_conf_application_code_add: '新增應用Xml配置',
    t_app_conf_application_code_edit: '編輯應用Xml配置',
    t_app_conf_application_code_delete: '刪除應用Xml配置',
    t_app_conf_job_code: '任務配置',
    t_app_conf_job_code_list: '任務配置',
    t_app_conf_job_code_add: '新增任務配置',
    t_app_conf_job_code_edit: '編輯任務配置',
    t_app_conf_job_code_delete: '刪除任務配置',
    t_app_conf_db_extract_conn_parameter_code: '數據庫參數配置',
    t_app_conf_db_extract_conn_parameter_code_list: '數據庫參數配置',
    t_app_conf_db_extract_conn_parameter_code_add: '新增數據庫參數配置',
    t_app_conf_db_extract_conn_parameter_code_edit: '編輯數據庫參數配置',
    t_app_conf_db_extract_conn_parameter_code_delete: '刪除數據庫參數配置',
    t_app_conf_db_extract_file_code: '數據庫抽取配置',
    t_app_conf_db_extract_file_code_list: '數據庫抽取配置',
    t_app_conf_db_extract_file_code_add: '新增數據庫抽取配置',
    t_app_conf_db_extract_file_code_edit: '編辑數據庫抽取配置',
    t_app_conf_db_extract_file_code_delete: '刪除數據庫抽取配置',
    t_app_conf_db_extract_file_parameter_value_code: '數據庫抽取參數值配置',
    t_app_conf_db_extract_file_parameter_value_code_list: '數據庫抽取參數值配置',
    t_app_conf_db_extract_file_parameter_value_code_add: '新增數據庫抽取參數配置',
    t_app_conf_db_extract_file_parameter_value_code_edit: '編輯數據庫抽取參數配置',
    t_app_conf_db_extract_file_parameter_value_code_delete: '刪除數據庫抽取參數配置',
    t_export_file_code: '导出管理',
    t_export_file_code_list: '文件列表',
    t_export_file_code_add: '新增导出文件',
    t_export_file_code_edit: '编辑导出文件',
    t_export_file_code_delete: '删除导出文件',
    t_report_subscribe_code: '报表订阅',
    t_report_subscribe_code_list: '订阅列表',
    t_report_subscribe_code_add: '新增报表订阅',
    t_report_subscribe_code_edit: '编辑报表订阅',
    t_report_subscribe_code_delete: '删除报表订阅',
    t_report_subscribe_run_code: '运行日志',
    t_prop_option_code: '客戶下拉字典管理',
    t_prop_option_code_list: '客戶下拉字典管理',
    install_check_compare: 'ERP数据比对',
    install_check_compare_purchase: '进货数据',
    install_check_compare_sales: '销售数据',
    install_check_compare_stock: '库存数据',
    distributorshop: '經銷商門店',
    distributorshop_list: '經銷商門店',
    shopmatch: '門店匹配',
    shopmatch_overview: '門店智能匹配总覽',
    shopmatch_shoplist: '門店匹配確認',
    shopmatch_client: '門店匹配',
    shopmatch_client_overview: '門店匹配確認总覽',
    shopmatch_client_shoplist: '門店匹配確認',
    distributorproduct: '經銷商產品',
    distributorproduct_list: '經銷商產品',
    productmatch: '產品匹配',
    productmatch_overview: '產品智能匹配总覽',
    productmatch_productlist: '產品匹配確認',
    productmatch_client: '產品匹配',
    productmatch_client_overview: '產品匹配確認总覽',
    productmatch_client_productlist: '產品匹配確認',
    distributorclientbilltype: '單據類型和狀態匹配',
    distributorclientbilltype_list: '單據類型和狀態匹配',
    distributorclientbilltype_add: '新增匹配',
    distributorclientbilltype_edit: '編輯匹配',
    distributorclientbilltype_delete: '刪除匹配',
    data_template: '數據模板',
    data_template_list: '下载模板管理',
    data_template_detail: '模板詳情',
    import_data: '數據導入',
    dms_rawdata: '原始數據查看',
    dms_rawdatasales: '原始銷售數據',
    dms_rawdatasales_list: '原始銷售數據',
    dms_rawdatasales_add: '新增原始銷售數據',
    dms_rawdatasales_edit: '編輯原始銷售數據',
    dms_rawdatasales_delete: '刪除原始銷售數據',
    dms_rawdatatransfer: '原始调拨數據',
    dms_rawdatatransfer_list: '原始调拨數據',
    dms_rawdatatransfer_add: '新增原始调拨數據',
    dms_rawdatatransfer_edit: '編輯原始调拨數據',
    dms_rawdatatransfer_delete: '刪除原始调拨數據',
    dms_rawdatapurchase: '原始進貨數據',
    dms_rawdatapurchase_list: '原始進貨數據',
    dms_rawdatapurchase_add: '新增原始進貨數據',
    dms_rawdatapurchase_edit: '編輯原始進貨數據',
    dms_rawdatapurchase_delete: '刪除原始進貨數據',
    dms_rawdatastock: '原始庫存數據',
    dms_rawdatastock_list: '原始庫存數據',
    dms_rawdatastock_add: '新增原始庫存數據',
    dms_rawdatastock_edit: '編輯原始庫存數據',
    dms_rawdatastock_delete: '刪除原始庫存數據',
    dms_rawdatachange: '原始其他出入庫數據',
    dms_rawdatachange_list: '原始其他出入庫數據',
    dms_rawdatachange_add: '新增原始其他出入庫數據',
    dms_rawdatachange_edit: '編輯原始其他出入庫數據',
    dms_rawdatachange_delete: '刪除原始其他出入庫數據',
    dms_matcheddata: '匹配後數據查看',
    dms_matcheddatasales: '匹配後銷售數據',
    dms_matcheddatasales_list: '匹配後銷售數據',
    dms_matcheddatasales_add: '新增匹配後銷售數據',
    dms_matcheddatasales_edit: '編輯匹配後銷售數據',
    dms_matcheddatasales_delete: '刪除匹配後銷售數據',
    dms_matcheddatatransfer: '匹配後调拨數據',
    dms_matcheddatatransfer_list: '匹配後调拨數據',
    dms_matcheddatatransfer_add: '新增匹配後调拨數據',
    dms_matcheddatatransfer_edit: '編輯匹配後调拨數據',
    dms_matcheddatatransfer_delete: '刪除匹配後调拨數據',
    dms_matcheddatapurchase: '匹配後進貨數據',
    dms_matcheddatapurchase_list: '匹配後進貨數據',
    dms_matcheddatapurchase_add: '新增匹配後進貨數據',
    dms_matcheddatapurchase_edit: '編輯匹配後進貨數據',
    dms_matcheddatapurchase_delete: '刪除匹配後進貨數據',
    dms_matcheddatastock: '匹配後庫存數據',
    dms_matcheddatastock_list: '匹配後庫存數據',
    dms_matcheddatastock_add: '新增匹配後庫存數據',
    dms_matcheddatastock_edit: '編輯匹配後庫存數據',
    dms_matcheddatastock_delete: '刪除匹配後庫存數據',
    dms_matcheddatachange: '匹配後其他出入庫數據',
    dms_matcheddatachange_list: '匹配後其他出入庫數據',
    dms_matcheddatachange_add: '新增匹配後其他出入庫數據',
    dms_matcheddatachange_edit: '編輯匹配後其他出入庫數據',
    dms_matcheddatachange_delete: '刪除匹配後其他出入庫數據',
    dms_converteddata: '轉換後數據查看',
    dms_converteddatasales: '轉換後銷售數據',
    dms_converteddatasales_list: '轉換後銷售數據',
    dms_converteddatasales_add: '新增轉換後銷售數據',
    dms_converteddatasales_edit: '編輯轉換後銷售數據',
    dms_converteddatasales_delete: '刪除轉換後銷售數據',
    dms_converteddatatransfer: '轉換後调拨數據',
    dms_converteddatatransfer_list: '轉換後调拨數據',
    dms_converteddatatransfer_add: '新增轉換後调拨數據',
    dms_converteddatatransfer_edit: '編輯轉換後调拨數據',
    dms_converteddatatransfer_delete: '刪除轉換後调拨數據',
    dms_converteddatapurchase: '轉換後進貨數據',
    dms_converteddatapurchase_list: '轉換後進貨數據',
    dms_converteddatapurchase_add: '新增轉換後進貨數據',
    dms_converteddatapurchase_edit: '編輯轉換後進貨數據',
    dms_converteddatapurchase_delete: '刪除轉換後進貨數據',
    dms_converteddatastock: '轉換後庫存數據',
    dms_converteddatastock_list: '轉換後庫存數據',
    dms_converteddatastock_add: '新增轉換後庫存數據',
    dms_converteddatastock_edit: '編輯轉換後庫存數據',
    dms_converteddatastock_delete: '刪除轉換後庫存數據',
    dms_converteddatachange: '轉換後其他出入庫數據',
    dms_converteddatachange_list: '轉換後其他出入庫數據',
    dms_converteddatachange_add: '新增轉換後其他出入庫數據',
    dms_converteddatachange_edit: '編輯轉換後其他出入庫數據',
    dms_converteddatachange_delete: '刪除轉換後其他出入庫數據',
    dms_settledata: '结存数据查看',
    dms_settledatasales: '结存销售数据',
    dms_settledatasales_list: '结存销售数据',
    dms_settledatasales_add: '新增结存销售数据',
    dms_settledatasales_edit: '编辑结存销售数据',
    dms_settledatasales_delete: '删除结存销售数据',
    dms_settledatatransfer: '结存调拨数据',
    dms_settledatatransfer_list: '结存调拨数据',
    dms_settledatatransfer_add: '新增结存调拨数据',
    dms_settledatatransfer_edit: '编辑结存调拨数据',
    dms_settledatatransfer_delete: '删除结存调拨数据',
    dms_settledatapurchase: '结存进货数据',
    dms_settledatapurchase_list: '结存进货数据',
    dms_settledatapurchase_add: '新增结存进货数据',
    dms_settledatapurchase_edit: '编辑结存进货数据',
    dms_settledatapurchase_delete: '删除结存进货数据',
    dms_settledatastock: '结存库存数据',
    dms_settledatastock_list: '结存库存数据',
    dms_settledatastock_add: '新增结存库存数据',
    dms_settledatastock_edit: '编辑结存库存数据',
    dms_settledatastock_delete: '删除结存库存数据',
    dms_settledatachange: '结存其他出入库数据',
    dms_settledatachange_list: '结存其他出入库数据',
    dms_settledatachange_add: '新增结存其他出入库数据',
    dms_settledatachange_edit: '编辑结存其他出入库数据',
    dms_settledatachange_delete: '删除结存其他出入库数据',
    pos_rawdata: '原始數據查看',
    pos_rawdatasales: '原始銷售數據',
    pos_rawdatatransfer: '原始调拨數據',
    pos_rawdatapurchase: '原始進貨數據',
    pos_rawdatastock: '原始庫存數據',
    pos_rawdatachange: '原始其他出入庫數據',
    pos_matcheddata: '匹配後數據查看',
    pos_matcheddatasales: '匹配後銷售數據',
    pos_matcheddatatransfer: '匹配後调拨數據',
    pos_matcheddatapurchase: '匹配後進貨數據',
    pos_matcheddatastock: '匹配後庫存數據',
    pos_matcheddatachange: '匹配後其他出入庫數據',
    pos_converteddata: '轉換後數據查看',
    pos_converteddatasales: '轉換後銷售數據',
    pos_converteddatatransfer: '轉換後调拨數據',
    pos_converteddatapurchase: '轉換後進貨數據',
    pos_converteddatastock: '轉換後庫存數據',
    pos_converteddatachange: '轉換後其他出入庫數據',
    pos_settledata: '结存数据查看',
    pos_settledatasales: '结存销售数据',
    pos_settledatatransfer: '结存调拨数据',
    pos_settledatapurchase: '结存进货数据',
    pos_settledatastock: '结存库存数据',
    pos_settledatachange: '结存其他出入库数据',
    distributorshopmatch: '經銷商門店匹配維護',
    distributorshopmatch_list: '門店匹配維護',
    distributorshopmatch_edit: '編輯經銷商門店匹配',
    distributorproductmatch: '經銷商產品匹配維護',
    distributorproductmatch_list: '產品匹配維護',
    distributorproductmatch_edit: '編輯經銷商產品匹配',
    distributorbalance: '进销存平衡检查',
    distributorbalance_check: '进销存平衡检查',
    distributorbalance_detail: '进销存平衡详细数据',
    duplicate_shop_check: '重复门店检查',
    productmatchcheck: '产品匹配检查',
    productmatchcheck1: '产品匹配检查1（经销商产品编码名称相同，匹配后产品标准编码不同）',
    productmatchcheck2: '产品匹配检查2（经销商产品编码名称相同，匹配后产品标准单位不同）',
    productmatchcheck3: '产品匹配检查3（经销商产品编码相同名称不相同，匹配后产品标准编码不同）',
    productmatchcheck4: '产品匹配检查4（经销商产品编码相同名称不相同单位相同，匹配后产品标准单位不同）',
    productmatchmarkuprate: '产品匹配加价率检查',
    client_period: '月结周期维护',
    client_period_list: '月结周期维护',
    client_period_add: '新增月结周期',
    client_period_edit: '编辑月结周期',
    app_manager: '客户端管理',
    data_view_dms: 'DMS数据查看',
    data_view_pos: 'POS数据查看',
    data_match: '数据匹配',
    data_check: '数据检查',
    data_dictionary_manage: '字典管理',
    data_dictionary_option: '數據字典',
    data_dictionary_prop: '數據表',
    api_log: '日志管理',
    wholesaler: '大仓/二批商',
    wholesaler_add: '新增直营大仓',
    wholesaler_edit: '编辑直营大仓',
    sapshop: 'SAP门店管理',
    sapshop_list: 'SAP门店管理',
    sapshop_add: '新增SAP门店',
    sapshop_edit: '编辑SAP门店',
    sap_mdm_shop_match: 'SAP与MDM编码匹配关系',
    gm_mercury_product: 'Mercury产品大表',
    gm_sap_file_load: 'SAP文件处理日志',
    todo4: '*公告管理',
    todo5: '*数据交互',
    todo6: '*采集字段维护',
    todo14: '*客户端公告',
    report: '報表管理',
    report_distributor_sales_product_top20: '经销商销售Top20产品',
    report_distributor_sales_shop_top20: '经销商销售Top20终端',
    report_distributor_pss: '经销商进销存报表',
    report_distributor_stock: '經銷商庫存報表',
    report_distributor_sales: '经销商销售报表',
    report_distributor_sales_rank: '经销商销售额排名报表',
    report_product_distribution: '产品分销报表',
    report_product_sales_increase_rate: '产品销售增长率Top10报表',
    todo27: '壹級經銷商出貨報表',
    todo28: '二級經銷商出貨報表',
    report_shop_number: 'DMS终端数量报表',
    report_shop_new: 'DMS新增终端报表',
    report_shop_lost: 'DMS流失终端报表',
    report_mdm_shop_number: '主数据终端数量报表'
  },
  navbar: {
    dashboard: '首頁',
    logOut: '退出登錄',
    modifyPass: '修改密碼',
    oldPass: '旧密码',
    newPass: '新密码',
    newPass2: '再次輸入新密碼',
    diffPass: '兩次輸入密碼不一致',
    size: '布局大小'
  },
  login: {
    title: '',
    resetPass: '重置密码',
    logIn: '登錄',
    username: '賬號',
    email: '郵箱',
    password: '密碼',
    send_reset_email_success: '提交成功，請查收郵件',
    reset_pass_success: '密碼重置成功',
    token_timeout: 'Token已失效，请重新登录'
  },
  label: {
    pleaseSelect: '請選擇',
    inputKeywordToFilter: '輸入關鍵字搜索',
    title404: '抱歉，您访问的页面不存在。',
    message404: '可能是因为输入的链接地址有误，或者您没有权限访问该页面。请点击下面按钮返回首页。',
    backToHome: '返回首页',
    recordChangeLog: '记录更新日志',
    similarShop: '相似門店',
    audit: '审批',
    auditRecordChange: '记录更新',
    auditSteps: '审批步骤',
    auditComment: '审批意见',
    auditCommentNotEmpty: '审批意见不能为空',
    auditNewShop: '门店新增申请',
    auditEditShop: '门店更新申请',
    newShop: '新店',
    view: '查看',
    checkSimilarShop: '疑似重复'
  },
  button: {
    refresh: '刷新',
    search: '搜索',
    add: '新增',
    export: '導出',
    import: '導入',
    edit: '編輯',
    delete: '刪除',
    cancel: '取消',
    reset: '重置',
    confirm: '確定',
    close: '關閉',
    back: '返回',
    save: '保存',
    clear: '清空',
    match: '匹配',
    matchConfirm: '確認',
    batchConfirm: '批量確認',
    calculateData: '计算数据量',
    operation: '操作',
    runSettle: '执行结存（日结+月结）',
    runMatch: '执行智能匹配',
    runDuplicateShopCheck: '执行重复门店检查',
    expand: '展开',
    collapse: '折叠',
    updateHistoricalData: '更新历史数据',
    reportEditor: '报表编辑器',
    generateChart: '生成图形',
    exportImage: '导出图片',
    pass: '通过',
    reject: '不通过',
    confirmAddShop: '确认添加新店',
    confirmDuplicated: '通过',
    confirmNotDuplicated: '不通过',
    reApply: '再次发起',
    loadSapFile: '从FTP加载SAP文件'
  },
  loading: {
    default: '玩命加載中...',
    login: '登录中...'
  },
  messageBox: {
    alert: {
      confirmButton: '確定'
    },
    confirm: {
      confirmButton: '確定',
      cancelButton: '取消',
      title: '確定',
      body: '您確定執行此操作嗎？'
    }
  },
  notification: {
    title: '提醒',
    success: {
      title: '成功',
      body: '操作成功'
    },
    error: {
      title: '错误',
      body: '操作失败',
      body_13: '提交门店審批成功'
    },
    loginFailed: {
      title: '錯誤',
      body: '登錄失敗: 錯誤的用戶名或密碼'
    }
  },
  table: {
    title: '標題',
    id: 'ID',
    actions: '操作',
    batchEdit: {
      beforeSelectedNumText: '',
      afterSelectedNumText: ' 條記錄被選中 ',
      openDialogButton: '批量更新',
      noSelectedData: '沒有記錄被選中，請勾選您想更新的記錄 ',
      dialogTitle: '批量更新',
      dialogTipsBeforeNum: '將以下更新應用於上面的',
      dialogTipsAfterNum: '條記錄: ',
      dialogTipsEqual: ' 等於 ',
      dialogNoInputWarn: '請輸入',
      dialogChooseUpdateColumn: '選擇...',
      dialogConfirmButton: '確定',
      dialogCancelButton: '取消',
      dialogSecondConfirm: '您確定執行此操作嗎？',
      dialogMessageTitle: '確定',
      successMessageTitle: '成功',
      successMessageBody: '操作成功'
    }
  },
  shopmatch: {
    noMoreShopMatched: '沒有更多匹配相似門店。',
    searchText: '試試輸入關鍵字搜索更多門店 (如有多个关键字，用空格分隔)：',
    searchPlaceholder: '門店編號/店名/地址',
    searchShopMatchConfirm: '您確定匹配這家門店嗎?',
    searchResultTipsBeforeNum: '最多顯示前',
    searchResultTipsAfterNum: '條記錄',
    searchResultBeforeNum: '搜索結果: ',
    searchResultAfterNum: '條記錄',
    noSelectedData: '沒有記錄被選中，請勾選您想更新的記錄',
    noSelectedShop: '请选择匹配的门店'
  },
  productmatch: {
    noMoreProductMatched: '沒有更多匹配相似產品。',
    searchText: '試試輸入關鍵字搜索更多產品 (如有多个关键字，用空格分隔)：',
    searchPlaceholder: '產品編號/名稱/规格',
    searchProductMatchConfirm: '您確定匹配這個產品嗎?',
    searchResultTipsBeforeNum: '最多顯示前',
    searchResultTipsAfterNum: '條記錄',
    searchResultBeforeNum: '搜索結果: ',
    searchResultAfterNum: '條記錄',
    noSelectedData: '沒有記錄被選中，請勾選您想更新的記錄',
    noSelectedProduct: '請選擇匹配的產品',
    noSelectedProductUnit: '請選擇匹配的產品單位',
    noProductUnitExchange: '无单位换算'
  },
  distributorshopmatch: {
    reverse: {
      title: '计算冲销数据量',
      distributorShop: '经销商门店',
      originalShop: '原匹配主数据门店',
      newShop: '变更匹配主数据门店',
      startDate: '冲销开始日期',
      endDate: '冲销结束日期',
      all: '全部',
      uptonow: '至今'
    },
    billType: {
      label: '单据类型',
      amount: '数量',
      minDate: '最小日期',
      maxDate: '最大日期',
      purchase: '进货单',
      sales: '销售单',
      stock: '库存',
      stockIn: '其他入库单',
      stockOut: '其他出库单'
    }
  },
  distributorproductmatch: {
    reverse: {
      title: '计算冲销数据量',
      distributorProduct: '经销商产品',
      originalProduct: '原匹配主数据产品',
      newProduct: '变更匹配主数据产品',
      startDate: '冲销开始日期',
      endDate: '冲销结束日期',
      all: '全部',
      uptonow: '至今'
    },
    billType: {
      label: '单据类型',
      amount: '数量',
      minDate: '最小日期',
      maxDate: '最大日期',
      purchase: '进货单',
      sales: '销售单',
      stock: '库存',
      stockIn: '其他入库单',
      stockOut: '其他出库单'
    }
  },
  report: {
    mode: {
      table: '表格',
      chart: '图形',
      chartType11: '普通折线图',
      chartType12: '堆叠折线图',
      chartType13: '面积图',
      chartType21: '普通柱状图',
      chartType22: '堆叠柱状图',
      chartType23: '横向柱状图',
      chartType31: '普通饼图'
    },
    selectChartType: '选择图形类别',
    selectXAxisFields: '选择X轴维度',
    selectYAxisFields: '选择Y轴维度',
    selectSeriesFields: '选择指标',
    percentage: '比例'
  },
  reportEditor: {
    dialogTitle: '报表编辑器',
    chooseGroupby: '请选择分组字段',
    chooseSelect: '请选择显示字段',
    unchecked: '未选择',
    checked: '已选择',
    dialogMessageNoChecked: '已选择字段不能为空，请选择',
    dialogConfirmButton: '确定',
    dialogCancelButton: '取消',
    dialogMessageTitle: '确定',
    successMessageTitle: '成功',
    successMessageBody: '操作成功'
  },
  rules: {
    login: {
      username: {
        required: '請輸入用戶名'
      },
      password: {
        required: '請輸入密碼'
      }
    },
    common: {
      required: '該項是必填項',
      maxlength10: '該項最大長度是10',
      maxlength20: '該項最大長度是20',
      maxlength50: '該項最大長度是50',
      maxlength100: '該項最大長度是100',
      maxlength200: '該項最大長度是200',
      maxlength500: '該項最大長度是500',
      cannotEmpty: '必填项不能为空',
      largerThan0: '数字必须大于0',
      cannotDuplicated: '该项不能重复',
      mustbe: '必须是',
      uploadExceed_1: '{0}最多能上傳{1}張',
      uploadExceed_2: '{0}最多能上傳{1}張'
    }
  },
  common: {
    info: '提示',
    delete_confirm: '是否要刪除?',
    yes: '是',
    no: '否',
    download: '下載',
    fileName: '文件名',
    fileSize: '文件大小',
    download_confirm: '確定下載？'
  },
  excel: {
    export: '導出',
    selectedExport: '導出已選擇項',
    placeholder: '請輸入文件名(默認excel-list)'
  },
  zip: {
    export: '導出',
    placeholder: '請輸入文件名(默認file)'
  },
  pdf: {
    tips: '這裏使用   window.print() 來實現下載pdf的功能'
  },
  theme: {
    change: '換膚',
    documentation: '換膚文檔',
    tips: 'Tips: 它區別於 navbar 上的 theme-pick, 是兩種不同的換膚方法，各自有不同的應用場景，具體請參考文檔。'
  },
  tagsView: {
    refresh: '刷新',
    close: '關閉',
    closeOthers: '關閉其它',
    closeAll: '關閉所有'
  },
  settings: {
    title: '系統布局配置',
    theme: '主題色',
    tagsView: '開啟 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '側邊欄 Logo'
  },
  dashboard: {
    panel_group: {
      uploaded_distributors: '已上传经销商数',
      purchase_amount_mtd: 'MTD进货额',
      purchase_mtd: 'MTD进货',
      current_month_target: '本月目标',
      target_completion_rate: '完成率',
      mom: '同比',
      yoy: '环比',
      stock: '库存',
      cases: '箱',
      turn_over_days: '周转天数',
      sale_amount_mtd: 'MTD出货额',
      sale_mtd: 'MTD出货'
    },
    stock_days_bar_chart: {
      stock_standard_amount: '库存标准金额',
      stock_turn_over_days: '库存周转天数'
    },
    shop_rank_table: {
      terminal_sales: '终端销售'
    },
    product_rank_table: {
      product_sales: '产品销售'
    },
    return_goods_line_chart: {
      product_return_trend: '产品退货趋势'
    },
    uploadmonitor: {
      item1: '总客户端',
      item2: '今日已上传',
      item3: '今日待上传',
      item4: '3日未上传'
    }
  },
  importFile: {
    importFile: '上傳文件',
    downloadTemplate: '下載數據模板',
    pleaseSelect: '請選擇...',
    importData: '數據導入',
    confirm: {
      title: '提示',
      confirmButton: '知道了',
      confirmButtonSee: '查看',
      cancelButton: '去[導出管理]看看'
    },
    importSuccess: '操作成功',
    importTimeout: '此次數據導入已超過{0}秒,系統正在異步處理中.稍後請刷新該頁面查看導入結果或者前往：系統設置-導出管理 查看',
    importFail: '導入文件有錯誤'
  },
  exportFile: {
    confirm: {
      title: '提示',
      confirmButton: '知道了',
      confirmButtonSee: '查看',
      cancelButton: '去[導出管理]看看'
    },
    exportSuccess: '操作成功',
    exportTimeout: '此次文件導出已超過{0}秒,系統正在異步處理中.稍後您可直接在菜單：系統設置-導出管理中下載.',
    exportRowTimeout: '此次文件導出記錄數已超過{0}條,系統正在異步處理中.稍後您可直接在菜單：系統設置-導出管理中下載.',
    exportFail: '導出失敗'
  }
};