import request from '@/utils/request';
export function overview(data) {
  return request({
    url: '/shopmatch/overview',
    method: 'post',
    data: data
  });
}
export function shoplist(data) {
  return request({
    url: '/shopmatch/shoplist',
    method: 'post',
    data: data
  });
}
export function citytree(id) {
  return request({
    url: '/shopmatch/citytree',
    method: 'post',
    params: {
      distributorId: id
    }
  });
}
export function mapping(id) {
  return request({
    url: '/shopmatch/mapping',
    method: 'post',
    params: {
      distributorShopId: id
    }
  });
}
export function match(data) {
  return request({
    url: '/shopmatch/match',
    method: 'post',
    data: data
  });
}
export function batchConfirm(data) {
  return request({
    url: '/shopmatch/batch-confirm',
    method: 'post',
    data: data
  });
}
export function searchShop(id, keyword) {
  return request({
    url: '/shopmatch/search-shop',
    method: 'post',
    params: {
      distributorShopId: id,
      keyword: keyword
    }
  });
}
export function calculateReverseData(id, startDate, endDate) {
  if (startDate == null) {
    startDate = '';
  }
  if (endDate == null) {
    endDate = '';
  }
  return request({
    url: '/shopmatch/calculate-reverse-data',
    method: 'post',
    params: {
      distributorShopId: id,
      startDate: startDate,
      endDate: endDate
    }
  });
}
export function run() {
  return request({
    url: '/shopmatch/run',
    method: 'post'
  });
}