var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { "flex-grow": "1", "margin-top": "12px" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.showUATtag
            ? _c(
                "span",
                { staticStyle: { "margin-right": "50px" } },
                [
                  _c("el-tag", { attrs: { type: "success", effect: "dark" } }, [
                    _vm._v("当前是UAT环境"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.device !== "mobile"
            ? [
                _vm.canSelectClient
                  ? _c("client-select", {
                      staticClass: "right-menu-item hover-effect",
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(this.$store.getters.username) +
                      " , " +
                      _vm._s(" " + _vm.name) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("navbar.size"),
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("size-select", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "size-select" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("lang-select", {
                  staticClass: "right-menu-item hover-effect",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "right-menu-item hover-effect",
                    on: { click: _vm.modifyPass },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { "icon-class": "changepassword" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "right-menu-item hover-effect",
                    on: { click: _vm.logoutConfirm },
                  },
                  [_c("svg-icon", { attrs: { "icon-class": "exit" } })],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "600px",
            visible: _vm.modifyPassDialogVisible,
            title: _vm.$t("navbar.modifyPass"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.modifyPassDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("navbar.oldPass") } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    model: {
                      value: _vm.oldPass,
                      callback: function ($$v) {
                        _vm.oldPass = $$v
                      },
                      expression: "oldPass",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("navbar.newPass") } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    model: {
                      value: _vm.newPass,
                      callback: function ($$v) {
                        _vm.newPass = $$v
                      },
                      expression: "newPass",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.modifyPassDialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmModifyPass },
                },
                [_vm._v(_vm._s(_vm.$t("button.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }