import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      // title: this.$t('index.system_name'), 
      systemName: ''
    };
  },
  computed: {
    logo: function logo() {
      if (this.$store.getters.logoUrl != '') {
        return this.$store.getters.logoUrl;
      } else {
        return '';
      }
    }
  },
  created: function created() {
    this.getSystemName();
  },
  methods: {
    getSystemName: function getSystemName() {
      var url = window.location.href;
      if (url.includes('demo') || url.includes('139.196.123.239')) {
        this.systemName = this.$t('index.system_name2');
      } else {
        this.systemName = this.$t('index.system_name1');
      }
    }
  }
};