import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _objectSpread from "/var/lib/jenkins/workspace/gmi-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang
import elementTwLocale from 'element-ui/lib/locale/lang/zh-TW'; // element-ui lang

import enLocale from "./en";
import zhLocale from "./zh";
import twLocale from "./tw";
Vue.use(VueI18n);
var messages = {
  en: _objectSpread(_objectSpread({}, enLocale), elementEnLocale),
  zh: _objectSpread(_objectSpread({}, zhLocale), elementZhLocale),
  tw: _objectSpread(_objectSpread({}, twLocale), elementTwLocale)
};
export function getLanguage() {
  var chooseLanguage = Cookies.get('language');
  var languageCodes = {
    '0': 'en',
    '1': 'zh',
    '2': 'tw'
  };
  if (chooseLanguage) return languageCodes[chooseLanguage];

  // if has not choose language
  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);
  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'en';
}
var i18n = new VueI18n({
  // set locale
  // options: en | zh | tw
  locale: getLanguage(),
  // set locale messages
  messages: messages
});
export default i18n;