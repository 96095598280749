var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter" },
        [
          !_vm.isCollapse
            ? _c("el-input", {
                staticClass: "filter-input",
                attrs: {
                  "suffix-icon": "el-icon-search",
                  clearable: "",
                  placeholder: _vm.$t("label.inputKeywordToFilter"),
                },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": false,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            _vm._l(_vm.routes, function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: {
                  item: route,
                  "base-path": route.path,
                  "filter-text": _vm.filterText,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }